import { inflate } from 'pako';

import { GenomicRange } from './types';

function parseCoordinate(match: RegExpMatchArray | null, field: string): number {
    const value = match?.groups && match.groups[field];
    return value ? +value.replace(',', '') : -1;
}

export function matchIsGenomicCoordinate(match: RegExpMatchArray | null): boolean {
    const start = parseCoordinate(match, 'start');
    const end = parseCoordinate(match, 'end');
    return (
        match !== null &&
        match.groups !== undefined &&
        match.groups.chromosome !== undefined &&
        match.groups.start !== undefined &&
        match.groups.end !== undefined &&
        !isNaN(start) &&
        start > 0 &&
        !isNaN(end) &&
        end > 0
    );
}

export function matchGenomicRegion(region: string): RegExpMatchArray | null {
    return /(?<chromosome>[A-Za-z0-9_]+)[:\t ](?<start>[0-9]+)[-\t ](?<end>[0-9]+)/g.exec(region.replace(/,/g, ''));
}

export function matchBedLine(line: string): RegExpMatchArray | null {
    return /(?<chromosome>[A-Za-z0-9_]+)\t(?<start>[0-9]+)\t(?<end>[0-9]+)/g.exec(line);
}

function parseRegionGeneric(region: string, matchFunction: (region: string) => RegExpMatchArray | null): GenomicRange {
    const match = matchFunction(region.replace(/,/g, ''));
    if (!matchIsGenomicCoordinate(match))
        throw new Error(`${region} could not be interpreted as a valid genomic coordinate`);
    return {
        chromosome: match?.groups!.chromosome!,
        start: parseCoordinate(match, 'start'),
        end: parseCoordinate(match, 'end'),
    };
}

export function parseRegion(region: string): GenomicRange {
    return parseRegionGeneric(region, matchGenomicRegion);
}

export function parseBedLine(region: string): GenomicRange | null {
    try {
        return parseRegionGeneric(region, matchBedLine);
    } catch (e) {
        return null;
    }
}

export function isValidRegion(region: string): boolean {
    return matchIsGenomicCoordinate(matchGenomicRegion(region));
}

export function parseBedContents(text: string): GenomicRange[] {
    return text
        .split('\n')
        .map(parseBedLine)
        .filter((x) => x !== null)
        .map((x) => x!);
}

export function readBed(file: File, onComplete: (regions: GenomicRange[]) => void, onError: (error: any) => void) {
    const textReader = new FileReader();
    const loadBinary = () => {
        const binaryReader = new FileReader();
        binaryReader.onload = (e) => {
            try {
                const results = parseBedContents(new TextDecoder().decode(inflate((e.target?.result || '') as string)));
                if (results.length === 0) throw new Error(`No regions were found in ${file.name}`);
                e.target && onComplete(results);
            } catch (e) {
                onError(e);
            }
        };
        binaryReader.readAsBinaryString(file);
        binaryReader.onerror = onError;
    };
    textReader.onload = (e) => {
        try {
            const newRegions = e.target ? parseBedContents(e.target.result as string) : [];
            if (newRegions.length > 0) onComplete(newRegions);
            else loadBinary();
        } catch (e) {
            loadBinary();
        }
    };
    textReader.onerror = loadBinary;
    textReader.readAsText(file);
}

export function readLines(file: File, onComplete: (results: string[]) => void, onError: (error: any) => void) {
    const textReader = new FileReader();
    const loadBinary = () => {
        const binaryReader = new FileReader();
        binaryReader.onload = (e) => {
            try {
                const results = new TextDecoder().decode(inflate((e.target?.result || '') as string)).split('\n');
                if (results.length === 0) throw new Error(`No regions were found in ${file.name}`);
                e.target && onComplete(results);
            } catch (e) {
                onError(e);
            }
        };
        binaryReader.readAsBinaryString(file);
        binaryReader.onerror = onError;
    };
    textReader.onload = (e) => {
        try {
            const newResults = e.target ? (e.target.result as string).split('\n') : [];
            if (newResults.length > 0) onComplete(newResults);
            else loadBinary();
        } catch (e) {
            loadBinary();
        }
    };
    textReader.onerror = loadBinary;
    textReader.readAsText(file);
}

export function mergeRegions(regions: GenomicRange[]): GenomicRange[] {
    if (regions.length <= 1) return regions;
    regions.sort((a, b) => (a.chromosome < b.chromosome ? -1 : a.chromosome > b.chromosome ? 1 : a.start - b.start));
    const results = [regions[0]];
    for (let i = 1; i < regions.length; ++i) {
        if (
            regions[i].chromosome === results[results.length - 1].chromosome &&
            regions[i].start <= results[results.length - 1].end
        ) {
            if (regions[i].end > results[results.length - 1].end) results[results.length - 1].end = regions[i].end;
        } else results.push(regions[i]);
    }
    return results;
}
