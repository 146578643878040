import React from 'react';

import WrappedTrack from '../wrapped';
import { WrappedRulerTrackProps } from './types';
import { RulerTrackProps } from '../../ruler';
import { WrappedTrackProps } from '../types';
import { RulerTrack } from './../../ruler/';

const WrappedRulerTrack: React.FC<WrappedRulerTrackProps> = props => (
    <WrappedTrack {...(props as WrappedTrackProps)} id='ruler' trackType='ruler'>
        <RulerTrack {...(props as RulerTrackProps)} />
    </WrappedTrack>
);
export default WrappedRulerTrack;
