import { Rect } from './types';
import { groupFeatures } from '../../../utils/coordinates';
import { Domain } from './../../../utils/types';
/**
 * Renders dense Motif data to SVG rectangles; overlapping regions are merged into single rectangles.
 * @param data input data vector.
 * @param x a transform function for mapping data coordinates to SVG coordinates.
 */
export function renderDenseMotifData(data: Rect[], x: (value: number) => number, domain: Domain): Rect[] {
    let results: Rect[] = [];
    const d = data.sort( (a, b) => a.start - b.start )
    console.log(d)
    d.forEach( (current, i) => {        
        let s = 0, e = current.pwm && current.pwm.length || 0;
        s = (current.start < domain.start) ? (domain.start - current.start) : 0
        e  = (current.end > domain.end) ? (domain.end - current.start): e
        let  pwm  = current.pwm ? current.pwm?.slice(s,e) : undefined
      
        if (i === 0 || current.start > d[i - 1].end){
          
            results.push({ 
                start: x(current.start) < 0 ? 0 : x(current.start), 
                end: x(current.end) < 0 ? 0 : x(current.end), 
                pwm: pwm
            })
        }
        else 
            results[results.length - 1].end = x(current.end);
       
    });
    return results;
}

export function renderSquishMotifData(data: Rect[], x: (value: number) => number, domain: Domain): Rect[][] {
    
    return groupFeatures(
        data.sort( (a, b) => a.start - b.start ).map(
            x => ({ coordinates: { start: x.start, end: x.end }, pwm: x.pwm, name: "" })
        ), x, 0
    ).map( group => (
        group.map( feature => { 
            
            let s = 0, e = feature.pwm && feature.pwm.length || 0;
            s = (feature.coordinates.start < domain.start) ? (domain.start - feature.coordinates.start) : 0
            e  = (feature.coordinates.end > domain.end) ? (domain.end - feature.coordinates.start): e
            let  pwm  = feature.pwm ? feature.pwm?.slice(s,e) : undefined
            return({
            start: x(feature.coordinates.start) < 0 ? 0 : x(feature.coordinates.start),
            end: x(feature.coordinates.end),          
            pwm: pwm
        })})
    ));
}