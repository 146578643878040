import React, { useMemo, useRef, useEffect } from 'react';
import { v1 as uuidv1 } from 'uuid';

import { ytransform } from '../../../utils/coordinates';
import { lighten } from '../../../utils/colors';
import { renderBigWig, datarange, renderDense } from './utils';
import { DenseBigWigProps, RenderedBigWigData } from './types';
import { ValuedPoint } from 'utils/types';
import { BigWigData } from 'bigwig-reader';
import { BigZoomData } from 'bigwig-reader';

/**
 * Represents a BigWig track rendered in dense mode as at UCSC. A dense BigWig uses a gradient of
 * colors to represent signal values, with darker colors representing higher signal.
 */
const DenseBigWig: React.FC<DenseBigWigProps> = props => {

    const uuid = useRef(uuidv1());
    const rendered: RenderedBigWigData = useMemo(() => (
        props.data && props.data.length && (props.data[0] as ValuedPoint).x !== undefined
            ? renderDense(props.data as ValuedPoint[])
            : renderBigWig(props.data as BigWigData[] | BigZoomData[], 100)
    ), [ props.data, props.width, props.domain ]);
    if (!rendered.range) rendered.range = datarange(rendered.renderPoints);
    const stopColors = useMemo( () => {
        const y = ytransform(rendered.range, 1.0);
        const renderPoints = rendered.renderPoints.filter(v => v.min < Infinity && v.max > -Infinity);
        const color = props.color || "#000000";      
        return renderPoints.map( point => lighten(color,y(point.max)) );
    }, [ props.color, rendered ]);
  
    useEffect( () => {
        props.onHeightChanged && props.onHeightChanged(props.height);
    }, [ props.height ]);
    
	return (
        <g className={props.className} width={props.width} height={props.height} transform={props.transform}>
            <defs>
		        <linearGradient id={uuid.current}>
                    { stopColors.map( (color, i) => (
                        <stop key={`${uuid.current}_pt_${i}`} offset={`${i}%`} stopColor={color} />
                    ))}
                </linearGradient>
	        </defs>
	        <rect width={props.width} x={0} y={props.height / 3.0} height={props.height / 3.0} fill={`url('#${uuid.current}')`} />
	    </g>
	);
    
}
export default DenseBigWig;
