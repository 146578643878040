import React from 'react';

import WrappedTrack from '../wrapped';
import { WrappedDenseBigWigProps } from './types';
import { DenseBigWig, DenseBigWigProps } from '../../bigwig';
import { WrappedTrackProps } from '../types';

const WrappedDenseBigWig: React.FC<WrappedDenseBigWigProps> = props => (
    <WrappedTrack {...(props as WrappedTrackProps)} trackType='bigwig' displayMode='dense'>
        <DenseBigWig {...(props as DenseBigWigProps)} />
    </WrappedTrack>
);
export default WrappedDenseBigWig;
