import React, { useMemo, useState, useEffect } from 'react';


import { useImportanceTrackData, GenomicRange} from './hooks';
import ImportanceTrack, { ImportanceTrackAnnotation, ImportanceTrackDataPoint, TrackProps } from './ImportanceTrack';

/**
 * Properties of a GraphQLImportanceTrack component.
 * @member endpoint URL for a GraphQL server implementing a bigRequest query; /graphql will be appended on request.
 * @member signalURL URL for a BigWig file containing importance data accessible to the GraphQL server.
 * @member sequenceURL URL for a 2bit sequence file accessible to the GraphQL server.
 * @member coordinates the genomic coordinates for which to request data.
 */
export type GraphQLImportanceTrackProps = TrackProps & {
    endpoint: string;
    signalURL: string;
    sequenceURL: string;
    coordinates: GenomicRange;
    allowSelection?: boolean;
    onSelectionEnd?: (coordinates: [ number, number ], values: ImportanceTrackDataPoint[]) => void;
    annotations?: ImportanceTrackAnnotation[];
    onSequenceLoaded?: (sequence: string) => void;
};

/**
 * 
 * A sequence/importance track which fetches its own data from a GraphQL endpoint implementing a bigRequest query.
 * Must be rendered within an SVG element. See https://www.github.com/weng-lab/signal-service for more information
 * on supported endpoints. Data is obtained from a pair of a BigWig file with importance data and a 2bit file with
 * sequence data.
 * 
 * @param props component properties; see TrackProps and GraphQLImportanceTrackProps.
 * @returns a rendered GraphQLImportanceTrack component instance.
 * 
 */
const GraphQLImportanceTrack: React.FC<GraphQLImportanceTrackProps> = props => {
    const signalURLs = useMemo( () => [ props.signalURL ], [ props.signalURL ]);    
    const [trackLoading, setTrackLoading]  = useState<boolean>(false)
    const { data } = useImportanceTrackData(props.endpoint, signalURLs, props.sequenceURL, props.coordinates);
    const importance = useMemo( () => {
        const d = [ ...Array(props.coordinates.end - props.coordinates.start) ].map(_ => 0);
        data && data[1].data.forEach(x => d[x.end - props.coordinates.start] = x.value);
        setTrackLoading(false)
        return d;
    }, [ data ]);
    const rendered = useMemo( () => data && ({
        sequence: data[0].data[0],
        importance
    }), [ data ]);
    
    useEffect( () => {
        setTrackLoading(true);
    }, [ props.coordinates.chromosome, props.coordinates.start, props.coordinates.end ]);

    useEffect( () => {
        if (!data || !props.onSequenceLoaded) return;
        props.onSequenceLoaded(data[0].data[0]);
    }, [ data, props ]);
    
    return (
        <>
            {trackLoading && <text x={0} y={props.height/2}> Loading...</text>}
            { !trackLoading && data && rendered && (
                <ImportanceTrack
                    width={props.width}
                    height={props.height}
                    data={rendered}
                    onBaseMousedOut={props.onBaseMousedOut}
                    onBaseMousedOver={props.onBaseMousedOver}
                    allowSelection={props.allowSelection}
                    onSelectionEnd={props.onSelectionEnd}
                    annotations={props.annotations}
                />
            )}
        </>
    );
}
export default GraphQLImportanceTrack;
