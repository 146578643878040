import React, { useState } from 'react';
import { CustomBigWigProps } from './types';

const CustomBigWig: React.FC<CustomBigWigProps> = props => {

    const [ url, setUrl ] = useState("https://");
    const [ title, setTitle ] = useState("title");

    return (
        <>
            add custom {props.text || "BigWig"}:&nbsp;
            <input type="text" size={50} value={url} onChange={e => setUrl(e.target.value)} />
            <input type="text" size={50} value={title} onChange={e => setTitle(e.target.value)} />
            <input type="button" value="add" onClick={() => { props.onSubmit && props.onSubmit({ url, title }); }} />
        </>
    );
    
}
export default CustomBigWig;
