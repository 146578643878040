export const svgPoint = (svg: SVGSVGElement, event:  React.MouseEvent<SVGElement>) => {    
    if (svg.createSVGPoint && svg) {
        let point = svg.createSVGPoint()
        point.x = event.clientX
        point.y = event.clientY
        point = point.matrixTransform(svg!.getScreenCTM()!.inverse())
        return [point.x, point.y]
    }
    const rect = svg.getBoundingClientRect()
    return [event.clientX - rect.left - svg.clientLeft, event.clientY - rect.top - svg.clientTop]
}

  
/* extract SVG data from DOM node, remove formatting from source code */
const _svgdata = (_svg: SVGSVGElement, _scale: SVGSVGElement) => {      
    let svg: any = _svg.cloneNode(true);
    let scalesvg: any = _scale.cloneNode(true);
    let preface = '<?xml version="1.0" standalone="no"?>';
    return (preface +  '<svg xmlns="http://www.w3.org/2000/svg">' + scalesvg.outerHTML.replace(/\n/g, "").replace(/[ ]{8}/g, "") + '<g transform="translate (0,50)">' +svg.outerHTML.replace(/\n/g, "").replace(/[ ]{8}/g, "") + '</g>' + '</svg>');
};
  
  export const downloadSVG = (_svg: SVGSVGElement, _scale: SVGSVGElement, filename: string) => {
    let svgBlob = new Blob([_svgdata(_svg, _scale)], { type: "image/svg;charset=utf-8" });    
    let svgUrl = URL.createObjectURL(svgBlob);    
    let downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  