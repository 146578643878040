import React from 'react';
import { ManhattanSNP } from './types';

const STYLE = {
    fontSize: "12px",
    backgroundColor: "#ffffff",
    border: "1px solid",
    padding: "1em"
};

export const TooltipContent: React.FC<ManhattanSNP> = snp => (
    <div style={STYLE}>
        {snp.data.rsId ? `name: ${snp.data.rsId}` : ""}<br />
        {`p-value: ${snp.data.score}`}<br />
    </div>
);
export default TooltipContent;
