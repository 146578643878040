import React from 'react';

import WrappedTrack from '../wrapped';
import { WrappedFullBigWigProps } from './types';
import { FullBigWigProps, FullBigWig } from '../../bigwig';
import { WrappedTrackProps } from '../types';

const WrappedFullBigWig: React.FC<WrappedFullBigWigProps> = props => (
    <WrappedTrack {...(props as WrappedTrackProps)} trackType='bigwig' displayMode='full'>
        <FullBigWig {...(props as FullBigWigProps)} />
    </WrappedTrack>
);
export default WrappedFullBigWig;
