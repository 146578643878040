import React, { useReducer, Reducer, useEffect, useCallback } from 'react';
import { GenomeBrowserAction, ToolTipData, ContextMenuData } from './types';
import SelectRegion from './selectregion'
import { Domain } from '../../utils/types';
import StackedTracks from './stackedtracks';
import Tooltip from "../tooltip/tooltip";
import { TooltipContext } from "../tooltip/tooltipcontext";
import ContextMenu, { ContextMenuContext } from './../contextmenu/contextmenu';
import BrowserContextMenu from './contextmenu';

export type GenomeBrowserProps = {
    domain: Domain;
    innerWidth: number;
    width: number | string;
    dragRegionHeight?: number;
    svgRef?: React.RefObject<SVGSVGElement>;
    onModeChange?: (id: string, displayMode: string) => void
    onDomainChanged?: (domain: Domain) => void;
    noMargin?: boolean;
};

export type GenomeBrowserState = {
    domain: Domain;
    width: number;
    height: number;
    tooltip: ToolTipData;
    contextmenu: ContextMenuData;
};

function reducer(previousState: GenomeBrowserState, action: GenomeBrowserAction): GenomeBrowserState {
    switch (action.type) {
        case "domainChanged":
            return { ...previousState, domain: action.newDomain };
        case "heightChanged":
            return { ...previousState, height: action.newHeight };
        case "tooltipChanged":
            return { ...previousState, tooltip: action.tooltip };
        case "contextMenuChanged":
            return { ...previousState, contextmenu: action.contextmenu };    
    }
}

/**
 * Provides a genome browser container which wraps children in an SVG
 * element and provides a mechanism for dragging to select regions.
 */
const GenomeBrowser: React.FC<GenomeBrowserProps> = props => {

    const [ state, dispatch ] = useReducer<Reducer<GenomeBrowserState, GenomeBrowserAction>>(
        reducer, {
            domain: props.domain,
            height: 0,
            width: props.innerWidth,
            tooltip: { show: false,
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                content: undefined
            },
            contextmenu: {
                show: false,
                x: 0,
                y: 0,
                id: '',
                displayMode: undefined,
                displayModeOptions: undefined
            }
        }
    );
    
    useEffect(() => {
        dispatch({ type: "domainChanged", newDomain: props.domain });
    }, [ props.domain ]);

    const setTooltip = useCallback((tooltip: ToolTipData) => {        
        dispatch({ type: "tooltipChanged", tooltip});
    }, [ dispatch ]);
    const setContextMenu = useCallback((contextmenu: ContextMenuData) => {
        dispatch({ type: "contextMenuChanged", contextmenu});
    }, [ dispatch ]);

    return (
        <TooltipContext.Provider value={{ ...state.tooltip, setTooltip }}>
            <ContextMenuContext.Provider value={{ ...state.contextmenu, setContextMenu }}>
                <svg
                    width={props.width}
                    viewBox={`0 0 ${state.width} ${state.height}`}
                    ref={props.svgRef}
                >
                    <SelectRegion
                        width={state.width}
                        height={state.height}
                        dragRegionHeight={props.dragRegionHeight || state.height}
                        leftMargin={props.noMargin ? 0 : 150}
                        domain={state.domain}
                        onSelectionEnd={ (domain: Domain) => props.onDomainChanged && props.onDomainChanged(domain) }
                    />
                    <StackedTracks
                        onHeightChanged={ height => dispatch({ type: "heightChanged", newHeight: height + 150 }) }
                        id="root"
                        svgRef={props.svgRef}
                    >
                        {props.children}
                    </StackedTracks>
                    <Tooltip width={state.tooltip.width} height={state.tooltip.height}>
                        <div style={{position: 'fixed'}}>{state.tooltip.content}</div>
                    </Tooltip>
                    <ContextMenu width={80} height={150}>
                        <BrowserContextMenu data={state.contextmenu} onModeChange={props.onModeChange} setContextMenu={setContextMenu} />
                    </ContextMenu>
                </svg>
            </ContextMenuContext.Provider>
        </TooltipContext.Provider>
    );

};
export default GenomeBrowser;
