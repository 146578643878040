import React, { useEffect, useState } from 'react';
import { isReactElement } from '../../../utils';
import { StackedTracks } from '../../browser';
import { VARIANT_QUERY, LD_QUERY } from './queries';
import { GraphQLLDTrackProps, SNP, LD } from './types';

const GraphQLLDTrack: React.FC<GraphQLLDTrackProps> = props => {

    const [ snps, setSnps ] = useState<SNP[] | null>(null);
    const [ ld, setLD ] = useState<{ld: LD[] | undefined}[]>();
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ error, setError] = useState<string | undefined>();

    useEffect(() => {
        setLoading(true);
        fetch(props.endpoint, {
            method: "POST",
            body: JSON.stringify({
                query: VARIANT_QUERY,
                variables: { 
                    assembly: props.assembly,
                    coordinates: {
                        chromosome: props.domain.chromosome,
                        start: props.domain.start,
                        end: props.domain.end
                    } 
                }
            }),
            headers: { "Content-Type": "application/json" }
        }).then(response => response.json()).then( d => {
            setSnps(d?.data?.snpQuery.filter((x: any) => x.coordinates).map(
                (x: any) => ({ ...x, domain: x.coordinates })
            ) || null);            
            if (d?.errors) setError(d?.errors.toString());
            setLoading(false);
        });
    }, [ props.assembly, props.domain ]);

    useEffect(() => {
        if (!props.anchor || !props.populations) return;
        fetch(props.endpoint, {
            method: "POST",
            body: JSON.stringify({
                query: LD_QUERY(props.populations || []),
                variables: { 
                    assembly: props.assembly,
                    snpids: [ props.anchor ]
                }
            }),
            headers: { "Content-Type": "application/json" }
        }).then( response => response.json()).then( d => {
            const ldData = d?.data?.snpQuery && d.data?.snpQuery[0];
            const ldRes = (props.populations || []).map( p => ({
                ld: (ldData && ldData[`${p.population}_${p.subpopulation || ""}_ld`]) || []
            }));
            setLD(ldRes);
            if (d?.errors) setError(d?.errors.toString());
        });
    }, [ props.assembly, props.anchor, props.populations ]);

    return (
        <StackedTracks transform={props.transform} id={props.id} height={0} onHeightChanged={props.onHeightChanged} svgRef={props.svgRef} >
            { React.Children.map( props.children, (child: React.ReactNode, i: number) => (
                isReactElement(child) ? (
                   snps ? React.cloneElement( child, { ...child.props, data: { snps, ld: (ld && ld[i]?.ld) || []  }, anchor: props.anchor, loading, error} ) : (React.cloneElement( child, { ...child.props, loading: true, error } ))
                ) : (
                    child
                )
            ))}
        </StackedTracks>
    );

}
export default GraphQLLDTrack;
