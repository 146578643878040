import React from 'react';
import Paginate from 'react-paginate';

import { PageMenuProps } from './types';

const PageMenu: React.FC<PageMenuProps> = props => (
    <Paginate
        previousLabel='<'
        nextLabel='>'
        breakLabel='...'
        pageCount={props.length}
        initialPage={props.selected}
        forcePage={props.selected}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        containerClassName='pagination'
        activeClassName='active'
        onPageChange={ ({ selected }) => { props.onPageSelect && props.onPageSelect(selected); } }
        disableInitialCallback={true}
    />
);
export default PageMenu;
