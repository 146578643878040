import React from 'react';

import ShiftButton from './shiftbutton';
import ZoomButton from './zoombutton';
import InputRegion from './inputregion';
import { UCSCControlProps } from './types';

const UCSCControls: React.FC<UCSCControlProps> = ({ domain, onDomainChanged, withInput }) => (
    <>
        move&nbsp;
        <ShiftButton text="<<<" shift={domain.start - domain.end} domain={domain} onClick={onDomainChanged} />
        <ShiftButton text="<<" shift={(domain.start - domain.end) / 2} domain={domain} onClick={onDomainChanged} />
        <ShiftButton text="<" shift={(domain.start - domain.end) / 4} domain={domain} onClick={onDomainChanged} />
        <ShiftButton text=">" shift={(domain.end - domain.start) / 4} domain={domain} onClick={onDomainChanged} />
        <ShiftButton text=">>" shift={(domain.end - domain.start) / 2} domain={domain} onClick={onDomainChanged} />
        <ShiftButton text=">>>" shift={domain.end - domain.start} domain={domain} onClick={onDomainChanged} />&nbsp;
        zoom in&nbsp;
        <ZoomButton text="1.5x" factor={1.0 / 1.5} domain={domain} onClick={onDomainChanged} />
        <ZoomButton text="3x" factor={1.0 / 3} domain={domain} onClick={onDomainChanged} />
        <ZoomButton text="10x" factor={1.0 / 10} domain={domain} onClick={onDomainChanged} />&nbsp;
        zoom out&nbsp;
        <ZoomButton text="1.5x" factor={1.5} domain={domain} onClick={onDomainChanged} />
        <ZoomButton text="3x" factor={3} domain={domain} onClick={onDomainChanged} />
        <ZoomButton text="10x" factor={10} domain={domain} onClick={onDomainChanged} />
        <ZoomButton text="100x" factor={100} domain={domain} onClick={onDomainChanged} />
        <br />
        { withInput && <InputRegion domain={domain} size={50} onSubmit={onDomainChanged} /> }
    </>
);
export default UCSCControls;
