import React, { useState, useRef, useCallback } from 'react';
import { Segment, Icon, Header, Button } from 'semantic-ui-react';
import { UploadSegmentProps } from './types';

const UploadSegment: React.FC<UploadSegmentProps> = (props) => {
    const fileInput = useRef<HTMLInputElement>(null);
    const [dimmed, setDimmed] = useState(false);

    const handleDrop = useCallback(
        (e: React.DragEvent) => {
            setDimmed(false);
            if (props.onFileReceived) {
                e.preventDefault();
                props.onFileReceived(e.dataTransfer.files);
            }
        },
        [props.onFileReceived, setDimmed]
    );

    const onFileChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            props.onFileReceived && e.target.files !== null && props.onFileReceived(e.target.files);
        },
        [props.onFileReceived]
    );

    return (
        <Segment
            placeholder
            onDragEnter={() => setDimmed(true)}
            onDragLeave={() => setDimmed(false)}
            onDragOver={(e: React.DragEvent) => {
                e.preventDefault();
            }}
            onDrop={handleDrop}
            className={dimmed ? 'dimmed' : ''}
            style={props.style}
        >
            <Header icon>
                <Icon name="file outline" style={{ fontSize: '36pt' }} />
                Drag and drop {props.fileType || 'BED'} files here.
            </Header>
            <Button primary onClick={() => fileInput && fileInput.current && fileInput.current.click()}>
                Browse Computer
            </Button>
            <input type="file" name="file" hidden ref={fileInput} onChange={onFileChange} multiple />
        </Segment>
    );
};
export default UploadSegment;
