import React, { useState, useEffect } from 'react';

import { TRANSCRIPT_REFSEQ_XENO_QUERY, TRANSCRIPT_REFSEQ_QUERY, TRANSCRIPT_GENES_QUERY } from './queries';
import { GraphQLTranscriptTrackProps, TranscriptList } from './types';
import { isReactElement } from '../../../utils';
import StackedTracks from './../../browser/stackedtracks';

const GraphQLTranscriptTrack: React.FC<GraphQLTranscriptTrackProps> = props => {

    const [ data, setData ] = useState<TranscriptList[] | null>(null);
    const [ loading, setLoading ] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let query = props.queryType==='refseqgenes' ? TRANSCRIPT_REFSEQ_QUERY : props.queryType==='refseqxenogenes' ? TRANSCRIPT_REFSEQ_XENO_QUERY : TRANSCRIPT_GENES_QUERY
            let variables = (props.queryType==='refseqgenes' || props.queryType==='refseqxenogenes') ? {
                assembly: props.assembly,
                coordinates: { chromosome:props.domain.chromosome!, start: props.domain.start, end: props.domain.end }
            } : {
                assembly: props.assembly,
                chromosome: props.domain.chromosome!,
                start: props.domain.start,
                end: props.domain.end
            }
            const response = await fetch(props.endpoint, {
                method: "POST",
                body: JSON.stringify({
                    query,
                    variables
                }),
                headers: { "Content-Type": "application/json" }
            });
            let d = props.queryType==='refseqgenes' ? (await response.json()).data?.refseqgenes  : props.queryType==='refseqxenogenes'  ? (await response.json()).data?.refseqxenogenes  : (await response.json()).data?.gene; 
            
            setData(d || null);
            setLoading(false);
        };
        fetchData();
    }, [ props.domain, props.assembly ]);

    return (
        <StackedTracks transform={props.transform} id={props.id} height={0} onHeightChanged={props.onHeightChanged} svgRef={props.svgRef}>
            { React.Children.map( props.children, (child: React.ReactNode) => (
                isReactElement(child) ? (
                   data ? (
                       React.cloneElement( child, { ...child.props, data, loading, onHeightChanged: (x: number) => { child.props.onHeightChanged && child.props.onHeightChanged(x); props.onHeightChanged && props.onHeightChanged(x); } } )
                   ) : React.cloneElement( child, { ...child.props, data, loading: true, onHeightChanged: (x: number) => { child.props.onHeightChanged && child.props.onHeightChanged(x); props.onHeightChanged && props.onHeightChanged(x); } } )
                ) : (
                    child
                )
             ))}
        </StackedTracks>
    );

};
export default GraphQLTranscriptTrack;
