import React from 'react';

import { TrackMarginProps, MarginTickProps } from './types';

export function bestFontSize(height: number): number {
    if (height / 6 < 10) return (height < 10 ? height : 10);
    return height / 6;
}

const MarginTick: React.FC<MarginTickProps> = ({ fontSize, position, width, children }) => (
    <>
        <text
            textAnchor="end"
            alignmentBaseline="middle"
            y={position - 3}
            x={width * 0.94}
            fontSize={`${fontSize}px`}
            style={{ pointerEvents: 'none', WebkitTouchCallout: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none'}}            
        >
            {children}
        </text>
        <line
            x1={width * 0.96}
            x2={width}
            y1={position}
            y2={position}
            stroke="#aaa"
        />
    </>   
);

const TrackMargin: React.FC<TrackMarginProps> = props => {
    const fontSize = bestFontSize(50);
    return (
        <g height={props.height} width={props.width}>
            <rect
                x={0}
                y={0}
                width={props.width / 15}
                height={props.height}
                stroke="#000000"
                strokeWidth={0.5}
                fill="rgb(225,225,225)"
            />
            <text
                fontSize={`${fontSize}px`}
                y={props.range === undefined ? props.height / 2 : (props.height + fontSize) / 2}
                x={props.width / 10}
                alignmentBaseline="middle"
                style={{ pointerEvents: 'none', WebkitTouchCallout: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none'}}
            >
                {props.shortLabel}
            </text>
            <line stroke="#ccc" x1={props.width} x2={props.width} y1={0} y2={props.height} />
            { props.range && (
                <>
                    <MarginTick
                        position={props.rangePositions.min}
                        width={props.width}
                        fontSize={fontSize || (props.height / 6)}
                    >
                        {props.range.min}
                    </MarginTick>
                    <MarginTick
                        position={props.rangePositions.max}
                        width={props.width}
                        fontSize={fontSize || (props.height / 6)}
                    >
                        {props.range.max}
                    </MarginTick>
                </>
            )}
        </g>
    );
};
export default TrackMargin;
