import React, { useState } from 'react';
import { isReactElement } from '../../../utils';
import { StackedTracks } from '../../browser';
import { MOTIF_QUERY } from './queries';
import { MotifTrackProps, BigResponse } from './types';
import useDeepCompareEffect from 'use-deep-compare-effect';


const MotifTrack: React.FC<MotifTrackProps> = props => {

    const [ data, setData ] = useState<BigResponse[] | null>(null);
    const [ pData, setPData ] = useState<BigResponse[] | null>(null);
    const [ loading, setLoading] = useState<boolean>(false);
    const [ error, setError] = useState<string | undefined>();
    useDeepCompareEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(props.endpoint, {
                    method: "POST",
                    body: JSON.stringify({
                        query: MOTIF_QUERY,
                        variables:  { consensus_regex:  props.track.consensus_regex, peaks_accession: props.track.peaks_accession, range: { chromosome: props.track.chromosome, start: props.track.start, end: props.track.end}, assembly: props.assembly, prange: { chrom: props.track.chromosome, chrom_start: props.track.start, chrom_end: props.track.end }, limit: props.track.limit, offset: props.track.offset }
                    }),
                    headers: { "Content-Type": "application/json" }
                });
                const d = await response.json();
                const motifData = d.data?.meme_occurrences && d.data?.meme_occurrences.map((m: { genomic_region: { start: number,end: number}, motif: { pwm?: number[][] }  } ) => ({
                    start: m.genomic_region.start,
                    end: m.genomic_region.end,
                    pwm: m.motif.pwm
                }));
                const peakData = d.data?.peaks.peaks.map((x: { chrom: string, chrom_start: number, chrom_end: number }) => ({
                    start: x.chrom_start,
                    end: x.chrom_end
                }));

                setData(motifData || null);
		setPData(peakData || undefined);
                if (d?.errors && d?.errors.length > 0) setError(d?.errors.toString());
                setLoading(false);
            } catch (e) {
                setLoading(false);
                setError(`Error loading data: ${e.message}`);
            }               
        };
        fetchData();
    }, [ props.track ]);
    return (
        <StackedTracks transform={props.transform} id={props.id} height={0} onHeightChanged={props.onHeightChanged} svgRef={props.svgRef}>
            { React.Children.map( props.children, (child: React.ReactNode) => {           
                
                return (
                    isReactElement(child) ? (
                        data
                            ? data.length == 0 ? React.cloneElement( child, { ...child.props, data, peaks: pData, noData: true } ): React.cloneElement( child, { ...child.props, data: data, peaks: pData, loading, error } ) 
                            :  (React.cloneElement( child, { ...child.props, loading: true, error } ))
                    ) : child
                );
            })}
        </StackedTracks>
    );

}
export default MotifTrack;
