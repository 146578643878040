import { BigWigData, BigBedData, BigZoomData } from "bigwig-reader";
import { Domain } from '../../../utils/types';
import { ValuedPoint } from 'utils/types';

export interface BigRequest {
    url: string;
    chr1: string;
    start: number;
    chr2?: string;
    end: number;
    zoomLevel?: number;
    preRenderedWidth?: number;
    googleProject?: string;
};

export type GraphQLTrackSetProps = {
    tracks: BigRequest[];
    id: string;
    transform: string;
    endpoint: string;
    width: number;
    onHeightChanged?: (value: number) => void;
    svgRef?: React.RefObject<SVGSVGElement>;
};

export type RestTracksetProps = {
    tracks: BigRequest[];
    id: string;
    transform: string;
    endpoint: string;
    width: number;
    onHeightChanged?: (value: number) => void;
    svgRef?: React.RefObject<SVGSVGElement>;

}
export type BamTrackProps = {
    track:  {
        bamUrl: string;
        baiUrl: string;
        chr: string;
        start: number;
        end: number; 
        color?: string;       
    },
    id: string;
    transform: string;
    endpoint: string;
    width: number;
    onHeightChanged?: (value: number) => void;
    svgRef?: React.RefObject<SVGSVGElement>;
}

export type MotifTrackProps = {
    track:  {
        chromosome: string;
        start: number;
        end: number; 
        peaks_accession?: string     
        consensus_regex?: string
        limit?: number,
        offset?: number
    },
    id: string;
    assembly: string;
    transform: string;
    endpoint: string;
    width: number;
    onHeightChanged?: (value: number) => void;
    svgRef?: React.RefObject<SVGSVGElement>;
}

export type BigResponseData = BigWigData[] | BigBedData[] | BigZoomData[] | ValuedPoint[];

export type BigResponse = {
    data: BigResponseData,
    error: RequestError
}

export type RequestError = {
    message: string,
    errortype: string
}


export type customTrackData = {
    title: string;
    color?: string;
    displayMode?: string;
    track: { start: number; end: number; chr1: string; url: string; baiUrl?: string; preRenderedWidth: number };
};

export type CustomTrackProps = {
    data?: any;
    loading?: boolean;
    svgRef?: React.RefObject<SVGSVGElement>;
    id: string;
    height: number;
    displayMode?: string;
    width: number;
    title: string;
    color: string;
    domain: Domain;
    onHeightChanged?: (value: number) => void;
    transform: string;
};

export const DEFAULT_BIGBED_DISPLAYMODE = 'dense';
export const DEFAULT_BIGWIG_DISPLAYMODE = 'full';
export const DEFAULT_BAM_DISPLAYMODE = 'squish';
