import React from 'react';
import { ShortHighlightProps } from './types';

const ShortHighlight: React.FC<ShortHighlightProps> = ({ highlight, height, x }) => {
    const PATH1 = `M ${x(highlight.start) - 5} 0 L ${x(highlight.start)} ${height * 0.2} L ${x(highlight.start) + 5} 0`;
    const PATH2 = `M ${x(highlight.start) - 5} ${height} L ${x(highlight.start)} ${height * 0.8} L ${x(highlight.start) + 5} ${height}`;
    return (
        <>
            <path
                d={PATH1}
                stroke={highlight.color || "#ff0000"}
                strokeWidth={3}
                fill="none"
            />
            <path
                d={PATH2}
                stroke={highlight.color || "#ff0000"}
                strokeWidth={3}
                fill="none"
            />
        </>
    );
};
export default ShortHighlight;
