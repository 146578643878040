import React from 'react';

import WrappedTrack from '../wrapped';
import { WrappedSquishBamProps } from './types';
import { SquishBam, SquishBamProps } from '../../bam';
import { WrappedTrackProps } from '../types';

const WrappedSquishBam: React.FC<WrappedSquishBamProps> = props => (
    <WrappedTrack {...(props as WrappedTrackProps)} trackType='bam' displayMode='squish'>
        <SquishBam {...(props as SquishBamProps)} />
    </WrappedTrack>
);
export default WrappedSquishBam;
