import React from 'react';

import WrappedTrack from '../wrapped';
import { WrappedSquishTranscriptProps } from './types';
import { WrappedTrackProps } from '../types';
import { SquishTranscriptTrack, SquishTranscriptTrackProps } from './../../transcripts';

const WrappedSquishTranscriptTrack: React.FC<WrappedSquishTranscriptProps> = props => (
    <WrappedTrack {...(props as WrappedTrackProps)} trackType='transcript' displayMode='squish'>
        <SquishTranscriptTrack {...(props as SquishTranscriptTrackProps)} />
    </WrappedTrack>
);
export default WrappedSquishTranscriptTrack;
