import React from 'react';
import { GlyphProps } from './types';

/**
 * Container component which scales a square glyph to the required dimensions.
 *
 * @prop yscale the factor by which to scale the glyph's height.
 * @prop xscale the factor by which to scale the glyph's width.
 * @prop inverted if set, reflects the glyph vertically.
 * @prop children the SVG contents to transform.
 */
const Glyph: React.FC<GlyphProps> = props => {
  const _yscale = props.yscale * (props.inverted ? -1 : 1);
  return (
    <g transform={'scale(' + props.xscale + ',' + _yscale + ')'}>
      {props.children}
    </g>
  );
};
export default Glyph;
