import React, { useState, useCallback } from 'react';
import { Grid, Input, Button, Divider } from 'semantic-ui-react';

import { UploadSegment } from '../UploadSegment';
import { UploadWithSearchBoxProps } from './types';

const UploadWithSearchBox: React.FC<UploadWithSearchBoxProps> = (props) => {
    const [region, setRegion] = useState('');
    const submitSearch = useCallback(() => {
        if (region == '' || (props.isSearchTextValid && !props.isSearchTextValid!(region))) {
            return;
        }
        props.onSearchSubmitted(region);
    }, [props.isSearchTextValid, props.onSearchSubmitted, region]);

    return (
        <Grid>
            <Grid.Row>
                <h2>{props.title}</h2>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <Grid.Column width={16} style={{ display: 'flex', justifyContent: 'left' }}>
                    {props.instructions}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingBottom: '0px' }}>
                <Grid.Column width={14}>
                    <Input
                        style={{ width: '100%' }}
                        icon="search"
                        onChange={(e) => setRegion(e.target.value)}
                        onKeyUp={(e: any) => e.keyCode === 13 && submitSearch()}
                        error={region != '' && props.isSearchTextValid ? !props.isSearchTextValid(region) : undefined}
                    />
                </Grid.Column>
                <Grid.Column width={2} style={{ padding: '0px' }}>
                    <Button onClick={submitSearch} style={{ width: '100%' }}>
                        Search
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: '0px' }}>
                <Grid.Column width={16} style={{ display: 'flex', justifyContent: 'left' }}>
                    <span style={{ fontStyle: 'italic' }}>{props.example}</span>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Divider horizontal>or</Divider>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <UploadSegment style={{ width: '100%' }} onFileReceived={props.onFilesReceived} />
            </Grid.Row>
        </Grid>
    );
};
export default UploadWithSearchBox;
