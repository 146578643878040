import React, { useReducer, useCallback } from 'react';
import { Container, Menu, Icon } from 'semantic-ui-react';

import { MultiSearchReducer, MultiRegionSearchProps } from './types';
import { multiSearchReducer } from './reducer';
import { CancelableModal, MergePrompt } from '../../modals';
import { UploadWithRegionSearchBox } from '../../upload';
import { GenomicRange } from '../../../utilities/types';
import { BedMerger } from '../../merge';

export type SearchByRegionProps = {
    factor?: string;
    assembly?: string;
    accession?: string;
    marginTop?: string;
};

function isGenomicRangeArray(x: GenomicRange[] | File[]): x is GenomicRange[] {
    return x.length >= 1 && (x as GenomicRange[])[0].chromosome !== undefined;
}

const MultiRegionSearch: React.FC<MultiRegionSearchProps> = (props) => {
    const [state, dispatch] = useReducer<MultiSearchReducer>(multiSearchReducer, {
        searches: [],
        tab: 0,
        pendingUpload: null,
        selection: null,
        downloading: false,
        addingSearch: false,
    });

    const setTab = useCallback(
        (tab: number) => {
            if (isGenomicRangeArray(state.searches[tab]))
                props.onSelectionChanged(state.searches[tab] as GenomicRange[]);
            dispatch({ type: 'TAB_SELECTED', tab });
        },
        [dispatch, props, state]
    );

    const addRegions = useCallback(
        (region: GenomicRange) => {
            props.onSelectionChanged([region]);
            dispatch({ type: 'REGIONS_ADDED', regions: [region] });
        },
        [dispatch, props, state]
    );

    return (
        <Container>
            {state.searches.length > 0 && (
                <Menu secondary pointing>
                    {state.searches.map((x, i) => (
                        <Menu.Item
                            key={i}
                            active={state.tab === i}
                            onClick={() => {
                                setTab(i);
                            }}
                        >
                            {isGenomicRangeArray(x) ? `Region Set ${i + 1}` : `File Set ${i + 1}`}
                        </Menu.Item>
                    ))}
                    <Menu.Item
                        onClick={() => {
                            dispatch({ type: 'SEARCH_ADD_REQUESTED' });
                        }}
                    >
                        <Icon name="add" /> Add Search
                    </Menu.Item>
                </Menu>
            )}
            <CancelableModal
                onCanceled={() => {
                    dispatch({ type: 'SEARCH_ADD_CANCELED_ACTION' });
                }}
                open={state.addingSearch}
            >
                <UploadWithRegionSearchBox
                    onFilesReceived={(files) => {
                        dispatch({ type: 'FILES_RECEIVED', files });
                    }}
                    onRegionSubmitted={addRegions}
                    title={props.title}
                    instructions={props.searchInstructions}
                />
            </CancelableModal>
            {state.pendingUpload ? (
                <MergePrompt
                    open
                    onAcceptMerge={() => {
                        dispatch({ type: 'FILE_MERGE_REQUESTED' });
                    }}
                    onAcceptSeparate={() => {
                        dispatch({ type: 'FILE_SEPARATE_REQUESTED' });
                    }}
                />
            ) : state.selection && state.selection.length > 0 && !isGenomicRangeArray(state.selection) ? (
                <BedMerger
                    onComplete={(regions) => {
                        props.onSelectionChanged(regions);
                        dispatch({ type: 'SET_SELECTION_ACTION', selection: regions });
                    }}
                    files={state.selection}
                />
            ) : state.selection === null ? (
                <UploadWithRegionSearchBox
                    onFilesReceived={(files) => {
                        dispatch({ type: 'FILES_RECEIVED', files });
                    }}
                    onRegionSubmitted={addRegions}
                    title={props.title}
                    instructions={props.searchInstructions}
                />
            ) : null}
        </Container>
    );
};
export default MultiRegionSearch;
