import React from 'react';

import WrappedTrack from '../wrapped';
import { WrappedDenseBamProps } from './types';
import { DenseBam, DenseBamProps } from '../../bam';
import { WrappedTrackProps } from '../types';

const WrappedDenseBam: React.FC<WrappedDenseBamProps> = props => (
    <WrappedTrack {...(props as WrappedTrackProps)} trackType='bam' displayMode='dense'>
        <DenseBam {...(props as DenseBamProps)} />
    </WrappedTrack>
);
export default WrappedDenseBam;
