import React from 'react';

import UploadWithSearchBox from './UploadWithSearchBox';
import { UploadWithRegionSearchBoxProps } from './types';
import { isValidRegion, parseRegion } from '../../../utilities/region';

const aStyle = {
    background: 'none',
    border: 'none',
    padding: 0,
    fontFamily: 'arial, sans-serif',
    color: '#069',
    cursor: 'pointer',
    outline: 'none',
};

const defaultExample = (props: UploadWithRegionSearchBoxProps) => (
    <span>
        {'example: '}
        <span
            style={aStyle}
            onClick={() =>
                props.onRegionSubmitted({
                    chromosome: 'chr1',
                    start: 100_000_000,
                    end: 100_001_000,
                })
            }
        >
            chr1:100,000,000-100,001,000
        </span>
    </span>
);

const UploadWithRegionSearchBox: React.FC<UploadWithRegionSearchBoxProps> = (props) => (
    <UploadWithSearchBox
        onFilesReceived={props.onFilesReceived}
        isSearchTextValid={isValidRegion}
        onSearchSubmitted={(value: string) => props.onRegionSubmitted(parseRegion(value))}
        example={props.example || defaultExample(props)}
        title={props.title}
        instructions={props.instructions === undefined ? "Enter genomic coordinates:" : props.instructions}
    />
);
export default UploadWithRegionSearchBox;
