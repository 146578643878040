import React from "react";
import { TooltipData } from './types';

const TooltipContext = React.createContext<TooltipData>({
    show: false,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    content: undefined,
    setTooltip: () => {}
});
  
export { TooltipContext };
