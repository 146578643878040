import React from 'react';

import { CytobandHighlightProps } from './types';
import ShortHighlight from './shorthighlight';

const CytobandHighlight: React.FC<CytobandHighlightProps> = props => (
    props.x(props.highlight.end) - props.x(props.highlight.start) < 10 ? (
        <ShortHighlight
            highlight={props.highlight}
            x={props.x}
            height={props.height}
        />
    ) : (
        <rect
            x={props.x(props.highlight.start)} y={0}
            width={props.x(props.highlight.end) - props.x(props.highlight.start)}
            height={props.height}
            fill={props.highlight.color || "#ff000088"}
        />
    )
);
export default CytobandHighlight;
