import React, { useCallback } from 'react';

import { GenomicRange } from '../../utilities/types';
import { mergeRegions, readBed } from '../../utilities/region';
import { BedMergerProps } from './types';
import Merger from './Merger';

const BedMerger: React.FC<BedMergerProps> = (props) => {
    const nextFile = useCallback((file: File, nextFile: (g: GenomicRange[]) => void) => {
        readBed(file, nextFile, () => nextFile([]));
    }, []);

    return props.files && props.files.length ? (
        <Merger
            onComplete={(regions: GenomicRange[]) => props.onComplete(mergeRegions(regions))}
            files={props.files}
            nextFile={nextFile}
        />
    ) : null;
};
export default BedMerger;
