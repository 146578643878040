import React, { useRef, useMemo } from 'react';
import { v1 as uuidv1 } from 'uuid';

import { xtransform } from '../../../utils/coordinates';
import { ClipPath } from '../../clippath';
import { LinkTrackProps } from './types';
import { renderSimpleLinks } from './utils';

/**
 * Represents a BigBed track rendered in dense mode as at UCSC. A dense BigBed renders genomic regions
 * of interest (such as peaks) as rectangles in a single row, with overlapping regions merged into a
 * single rectangle.
 */
const SimpleLinkTrack: React.FC<LinkTrackProps> = props => {

    const uuid = useRef(uuidv1());
    const x = xtransform(props.domain, props.width);
    const scoreThreshold = props.scoreThreshold || 0;
    const colorMax = props.colorMax || 0;

    const renderedLinks = useMemo(
        () => renderSimpleLinks(props.data, x, props.height, scoreThreshold, colorMax),
        [ props.width, props.domain, props.height, props.color, props.data, props.scoreThreshold, props.colorMax ]
    );

    return (
        <g width={props.width} height={props.height} transform={props.transform} clipPath={`url(#${uuid.current})`}>
            <defs>
                <ClipPath id={uuid.current} width={props.width} height={props.height} />
            </defs>
            { renderedLinks.map( (path, i) => (
                <path
                    d={path.d}
                    stroke={path.stroke}
                    strokeOpacity={props.arcOpacity || 0.7}
                    key={`${props.id}_path_${i}`}
                    fill="none"
                />
            ))}
        </g>
    );

};
export default SimpleLinkTrack;
