import React from 'react';

import WrappedTrack from '../wrapped';
import { WrappedPackTranscriptProps } from './types';
import { WrappedTrackProps } from '../types';
import { PackTranscriptTrack, PackTranscriptTrackProps } from './../../transcripts';

const WrappedPackTranscriptTrack: React.FC<WrappedPackTranscriptProps> = props => (
    <WrappedTrack {...(props as WrappedTrackProps)} trackType='transcript' displayMode='pack'>
        <PackTranscriptTrack {...(props as PackTranscriptTrackProps)} />
    </WrappedTrack>
);
export default WrappedPackTranscriptTrack;
