import React, { useState, useEffect } from 'react';
import { Modal, Progress } from 'semantic-ui-react';

import { ChunkedJSONSearchProps } from './types';
import { sleep } from '../../utilities/sleep';

function ChunkedJSONSearch<T, U, V>(
    props: ChunkedJSONSearchProps<T, U, V>
): React.ReactElement<ChunkedJSONSearchProps<T, U, V>> {
    const [percentage, setPercentage] = useState(0.0);

    useEffect(() => {
        let mounted = true;
        const callback = async (search: U[]) => {
            const reader = await props.getReader(search);
            if (reader === null) throw new Error('ChunkedJSONSearch component was unable to obtain a stream reader.');

            let results = '';
            let cProgress = 0.0;
            let done = false;
            let value: Uint8Array | undefined = undefined;

            while (!done && mounted) {
                const result = await reader!.read();
                done = result.done;
                value = result.value;
                if (value !== undefined) results += new TextDecoder('utf-8').decode(value);

                if (results.includes(props.delimiter) || done) {
                    const pe = results.split(props.delimiter);
                    const limit = pe.length - (done ? 0 : 1);
                    const currentResults: V[] = pe
                        .slice(0, limit)
                        .filter((x) => x.trim() !== '')
                        .map((x) => JSON.parse(x));

                    props.onBatchComplete(props.getResults(currentResults));

                    cProgress += props.getProgress(currentResults);
                    if (mounted) setPercentage(cProgress);
                    await sleep(20);
                    results = pe[pe.length - 1] || '';
                }
            }
        };

        callback(props.searchTerms)
            .then(() => {
                props.onComplete();
            })
            .catch((e) => {
                props.onError(e);
            });

        return () => {
            mounted = false;
        };
    }, [props, setPercentage]);

    return (
        <Modal open>
            <Modal.Header>Searching...</Modal.Header>
            <Modal.Content>
                <p>
                    The search has started. For large files, this can take several minutes. This dialog will close
                    automatically when the search is complete.
                </p>
                <Progress percent={percentage.toFixed(0)} progress size="medium" indicating />
            </Modal.Content>
        </Modal>
    );
}
export default ChunkedJSONSearch;
