import React, { useMemo, useRef, useEffect, useCallback } from 'react';
import { v1 as uuidv1 } from 'uuid';

import { Rect } from './types';
import { xtransform } from '../../../utils/coordinates';
import { ClipPath } from '../../clippath';
import { renderDenseBigBedData } from './utils';
import TooltipContent from './tooltip';
import { Domain } from '../../../utils/types';
import { useTooltip } from '../../../hooks/useTooltip';

export type DenseBigBedProps = {
    width: number;
    height: number;
    id?: string;
    transform?: string;
    color?: string;
    domain: Domain;
    data?: Rect[];
    svgRef?: React.RefObject<SVGSVGElement>;
    onHeightChanged?: (height: number) => void;
    onClick?: (item: Rect) => void;
    onMouseOver?: (item: Rect) => void;
    onMouseOut?: () => void;
    tooltipContent?: React.FC<Rect>;
    className?: string;
};

const DenseBigBed: React.FC<DenseBigBedProps> = props => {

    const [ mouseOver, mouseOut ] = useTooltip(props.tooltipContent || TooltipContent, props.width, props.svgRef);
    
    const uuid = useRef(uuidv1());
    const x = useCallback(xtransform(props.domain, props.width), [ props ]);
    const color = props.color || "#000000";
    const id = props.id || uuid.current.toString();

    const rendered: Rect[] = useMemo(
        () => renderDenseBigBedData(props.data!, x),
        [ props.domain, props.width, props.data ]
    ); 
    useEffect( () => {
        props.onHeightChanged && props.onHeightChanged(props.height);
    }, [ props.height ]);

    return (
        <g
            width={props.width}
            height={props.height}
            transform={props.transform}
            clipPath={`url(#${id})`}
            className={props.className}
        >
            <defs>
                <ClipPath id={id} width={props.width} height={props.height} />
            </defs>
            { rendered.map( (rect, i) => (
                <rect
                    key={`${id}_${i}`}
                    height={props.height * 0.6}
                    width={rect.end - rect.start}
                    x={rect.start}
                    y={props.height * 0.2}
                    fill={rect.color || color}
                    onClick={() => props.onClick && props.onClick(rect)}
                    onMouseOut={() => { props.onMouseOut && props.onMouseOut(); mouseOut(); }}
                    onMouseOver={(e: React.MouseEvent<SVGRectElement>) => { 
                        e.persist(); 
                        props.onMouseOver && props.onMouseOver(rect);
                        mouseOver(e, rect);
                    }} 
                />
            ))}
        </g>
    );
    
}
export default DenseBigBed;
