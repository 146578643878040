import React from 'react';

import WrappedTrack from '../wrapped';
import { WrappedDenseBigBedProps } from './types';
import { DenseBigBed, DenseBigBedProps } from '../../bigbed';
import { WrappedTrackProps } from '../types';

const WrappedDenseBigBed: React.FC<WrappedDenseBigBedProps> = props => (
    <WrappedTrack {...(props as WrappedTrackProps)} trackType='bigbed' displayMode='dense'>
        <DenseBigBed {...(props as DenseBigBedProps)} />
    </WrappedTrack>
);
export default WrappedDenseBigBed;
