import React from 'react';

import WrappedTrack from '../wrapped';
import { WrappedDenseMotifProps } from './types';
import { DenseMotif, DenseMotifProps } from '../../motif';
import { WrappedTrackProps } from '../types';

const WrappedDenseMotif: React.FC<WrappedDenseMotifProps> = props => (
    <WrappedTrack {...(props as WrappedTrackProps)} trackType='motif' displayMode='dense'>
        <DenseMotif {...(props as DenseMotifProps)} />
    </WrappedTrack>
);
export default WrappedDenseMotif;
