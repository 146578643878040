import React from 'react';

import WrappedTrack from '../wrapped';
import { WrappedLDTrackProps } from './types';
import { LDTrackProps } from '../../ld';
import { WrappedTrackProps } from '../types';
import { LDTrack } from './../../ld/';

const WrappedLDTrack: React.FC<WrappedLDTrackProps> = props => (
    <WrappedTrack {...(props as WrappedTrackProps)} trackType='ldtrack' displayMode='dense'>
        <LDTrack {...(props as LDTrackProps)} />
    </WrappedTrack>
);
export default WrappedLDTrack;
