import React, { useRef, useMemo, useContext, useEffect } from 'react';
import { v1 as uuidv1 } from 'uuid';

import { xtransform } from '../../../utils/coordinates';
import { ClipPath } from '../../clippath';
import { LDTrackProps, SNP } from './types';
import { renderLD, renderSNPs } from './utils';
import { TooltipContext } from "../../tooltip/tooltipcontext";
import { svgPoint } from '../../../utils/svg';

const LDTrack: React.FC<LDTrackProps> = props => {
    const tooltipContext = useContext(TooltipContext);
    const mouseOver = (event:  React.MouseEvent<SVGRectElement>, snp: SNP) => {  
        if(props.svgRef) {
            let mousePosition = svgPoint(props.svgRef.current!, event);
            tooltipContext.setTooltip({
                show: true,
                x: mousePosition[0] > props.width - 120 ?  (mousePosition[0] - 120) + 15:  mousePosition[0] + 15, 
                y: mousePosition[1] + 10,
                width: 120,
                height: 50,
                content: <span style={{fontSize:'10px'}} >{snp.id}</span>
            });
        }        
        props.onVariantMouseOver && props.onVariantMouseOver(snp)
    };
    
    const mouseOut = () => {
        tooltipContext.setTooltip({ show: false, x: 0, y: 0, content: undefined, width: 0, height: 0 });
    };
    
    
    const uuid = useRef(uuidv1());
    const x = xtransform(props.domain, props.width);
    const color = props.color || "#000000";
    const ldThreshold = props.ldThreshold || 0.7;
    const highlightColor = props.highlightColor || "#ff0000";
    const highlighted = props.highlighted || new Set();

    const renderedSNPs = useMemo(
        () => renderSNPs(props.data!.snps, highlighted, x, highlightColor, color),
        [ props.domain, props.width, props.data!.snps, props.highlighted, highlightColor, color ]
    );
    const renderedLD = props.data!.ld && useMemo(
        () => renderLD(renderedSNPs.coords, props.anchor || "", props.data!.ld!.filter(l=>l.coordinates), x, ldThreshold, props.height),
        [ renderedSNPs, props.height, props.anchor, props.data!.ld, props.domain, props.ldThreshold ]
    );
    useEffect( () => {
        props.onHeightChanged && props.onHeightChanged(props.height);
    }, [ props.height ]);
    const isAnchor = props.anchor && renderedSNPs.snps.find(r=>r.id===props.anchor)
    const anchorSnp =  renderedSNPs.snps.find(r=>r.id===props.anchor)
    return React.useMemo(() => {
        return (
            <g className={props.className} width={props.width} height={props.height} transform={props.transform} clipPath={`url(#${uuid.current})`}>
                <defs>
                    <ClipPath id={uuid.current} width={props.width} height={props.height} />
                </defs>
                {isAnchor && renderedLD?.length===0 &&  <text
                                fill={'#ff0000'}                            
                                fontSize={10}
                                x={renderedSNPs.snps.find(r=>r.id===props.anchor)?.domain.start}
                                y={20}
                                style={{ pointerEvents: 'none', WebkitTouchCallout: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none'}}                                            
                            >
                                {`There are no SNPs in LD (r-squared > ${ldThreshold}) with  ${props.anchor} in ${props.id} track`}			                    
			                </text>}
                {isAnchor && <rect
                            key={`${props.anchor}`}
                            height={ (props.height * (anchorSnp!.taller ? 1.0 : 0.6) * (props.anchor ? 0.25 : 0.6))}
                            width={1}
                            x={ anchorSnp!.domain.end}
                            y={props.height - (props.height * (anchorSnp!.taller ? 1.0 : 0.6) * (props.anchor ? 0.25 : 0.6))}
                            fill={'#0000ff'}
                        /> }                            
                { renderedSNPs.snps.map( (snp, i) => {
                    const height = props.height * (snp.taller ? 1.0 : 0.6) * (props.anchor ? 0.25 : 0.6);
                    return (
                        <rect
                            style={{cursor:'pointer'}} 
                            key={`${props.id}_${i}`}
                            height={height}
                            width={snp.domain.end - snp.domain.start}
                            x={snp.domain.start}
                            y={props.height - height}
                            fill={snp.color}
                            onMouseOver={(e: React.MouseEvent<SVGRectElement>)=> mouseOver(e, snp)}
                            onMouseOut={() => { mouseOut(); props.onVariantMouseOut && props.onVariantMouseOut(snp)}}
                            onClick={() => props.onVariantClick && props.onVariantClick(snp)}
                        />
                    );
                })}
                { renderedLD && renderedLD.map( (path, i) => (
                    <path d={path.d} stroke={path.stroke} key={`${props.id}_path_${i}`} fill="none" strokeWidth={2} />
                ))}
            </g>
        )
    },[ color, props.data?.snps, props.ldThreshold , props.highlighted, props.highlightColor, props.data?.ld, props.domain, props.height, props.width, props.id])
    
}
export default LDTrack;
