import React from 'react';
import {
    WrappedDenseBigBed,
    WrappedSquishBigBed,
    WrappedTrack,
    WrappedFullBigWig,
    WrappedDenseBigWig
} from '../wrapped';
import { EmptyTrack } from '../empty'
import { TrackType, deduceTrackType } from './deducetype';
import { CustomTrackProps } from './types';
import { DEFAULT_BIGBED_DISPLAYMODE, DEFAULT_BAM_DISPLAYMODE, DEFAULT_BIGWIG_DISPLAYMODE } from './types';

function getDisplayMode(trackType: string, displayMode?: string): string {
    if (displayMode) return displayMode;
    switch (trackType) {
        case TrackType.BIGBED:
            return DEFAULT_BIGBED_DISPLAYMODE;
        case TrackType.BIGWIG:
            return DEFAULT_BIGWIG_DISPLAYMODE;
        default:
            return DEFAULT_BAM_DISPLAYMODE;
    }
}

const CustomTrack: React.FC<CustomTrackProps> = (props) => {
    if (props.loading)
        return (
            <WrappedTrack {...props} id="emptytrack" width={props.width} height={props.height} loading>
                <EmptyTrack {...(props || {})} />
            </WrappedTrack>
        );
   /* if (!props.data) {
       
        if(props.displayMode==='hide')
        {   
            return (<WrappedTrack  {...props} width={props.width} height={0} id={props.id} title={undefined} >
                <EmptyTrack  width={props.width} transform={'translate (0,0)'} height={0} id={props.id} />
            </WrappedTrack>)

        }  else { return  (
            <WrappedTrack {...props} id={props.id} width={props.width} height={props.height} noData title={props.title}  titleSize={12}
            svgRef={props.svgRef}
            trackMargin={12}>
                <EmptyTrack  width={props.width}
                             transform={'translate (0,0)'}
                             height={props.height}
                             id={props.id}/>
            </WrappedTrack>
        );
        }
        
    }*/
    const trackType = deduceTrackType(props.data);
    const displayMode = getDisplayMode(trackType, props.displayMode);
    switch (trackType) {
        case TrackType.BIGBED:
            if (displayMode === 'squish')
                return (
                    <WrappedSquishBigBed
                        {...props}
                        title={props.title}
                        width={props.width}
                        height={props.height}
                        rowHeight={10}
                        transform={'translate (0,0)'}
                        id={props.id}
                        color={props.color}
                        domain={props.domain}
                        data={props.data}
                        titleSize={12}
                        trackMargin={12}
                    />
                );
            if (displayMode === 'hide')
                return (
                    <WrappedTrack
                        {...props}
                        width={props.width}
                        height={0}
                        title={undefined}
                        id={props.id}
                    >
                        <EmptyTrack 
                            width={props.width}
                            transform={'translate (0,0)'}
                            height={0}
                            id={props.id}/>
                    </WrappedTrack>
                );
            return (
                <WrappedDenseBigBed
                        {...props}
                        title={props.title}
                        width={props.width}
                        height={props.height}
                        transform={'translate (0,0)'}
                        id={props.id}
                        color={props.color}
                        domain={props.domain}
                        data={props.data}
                        titleSize={12}
                        trackMargin={12}
                />
            );
        case TrackType.BIGWIG:
            if (displayMode === 'hide')
                return (
                    <WrappedTrack
                        {...props}
                        width={props.width}
                        height={0}
                        id={props.id}
                    >
                        <EmptyTrack 
                            width={props.width}
                            transform='translate (0,0)'
                            height={0}
                            id={props.id}
                        />
                    </WrappedTrack>
                );
            if (displayMode === 'dense')
                return (
                    <WrappedDenseBigWig
                        {...props}
                        title={props.title}
                        width={props.width}
                        height={props.height}
                        transform='translate (0,0)'
                        id={props.id}
                        color={props.color}
                        domain={props.domain}
                        data={props.data}
                        titleSize={12}
                        trackMargin={12}
                    />
                );
            return (
                <WrappedFullBigWig
                    {...props}
                    title={props.title}
                    width={props.width}
                    height={props.height}
                    transform='translate (0,0)'
                    id={props.id}
                    color={props.color}
                    domain={props.domain}
                    data={props.data}
                    titleSize={12}
                    trackMargin={12}
                />
            );
        default:
            if (props.displayMode === 'hide')
                return (
                    <WrappedTrack  {...props} width={props.width} height={0} id={props.id}>
                        <EmptyTrack {...props} width={props.width} transform='translate (0,0)' height={0} id={props.id} />
                    </WrappedTrack>
                );
            return (
                <WrappedTrack {...props} id={props.id} width={props.width} height={props.height} noData title={props.title} titleSize={12} trackMargin={12}>
                    <EmptyTrack 
                        width={props.width}
                        transform='translate (0,0)'
                        height={props.height}
                        id={props.id}
                    />
                </WrappedTrack>
            );
    }
};
export default CustomTrack;
