import { ManhattanSNP, SummaryStatisticSNP } from './types';

/**
 * Renders summary statistic data to Manhattan plot shapes.
 * @param data input data vector.
 * @param x a transform function for mapping data coordinates to SVG coordinates.
 * @param y a transform function for mapping data scores to SVG coordinates.
 */
export function renderManhattanPlotData(data: SummaryStatisticSNP[], x: (value: number) => number, y: (value: number) => number): ManhattanSNP[] {
    return data.map( snp => ({
        x: x(snp.coordinates.start),
        y: y(-Math.log10(snp.score)),
        data: snp
    }), [ data ]);
}
