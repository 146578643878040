import React, { useState } from 'react';
import { RestTracksetProps, BigResponse } from './types';
import { isReactElement } from '../../../utils';
import { StackedTracks } from '../../browser';
import { ValuedPoint } from 'utils/types';
import { datarange, wigdatarange } from './../bigwig/utils';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { BigWigData } from 'bigwig-reader';
import { condensedData, parseMultiplexedResponse } from './utils';
import { formatPoint } from '../../../utils/coordinates';

const RestTrackSet: React.FC<RestTracksetProps> = props => {
    const [ data, setData ] = useState<BigResponse[] | null>(null);
    const [ loading, setLoading] = useState<boolean>(false);
    const [ error, setError] = useState<string | undefined>();

    useDeepCompareEffect(()=>{
        const fetchData = async () => {       
            let bigData = '', d = true;
            let testData: any = []
            try {
                setLoading(true);
                const response = await fetch(props.endpoint+'/big', {
                    method: 'post',
                    body: JSON.stringify(props.tracks),
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                });
                const reader = response && response.body && response.body.getReader();
                while (d) {
                    const { done, value } = await reader!.read();
                    const val = new TextDecoder('utf-8').decode(value);
                    bigData += val;
                    if (done) d = false;
                }
                const q = parseMultiplexedResponse(bigData);
                q.forEach(f => { testData.push({ data: f }) });
                setData(testData);
                setLoading(false);
            } catch (e) {
                setError('Error Occurred');
                console.log(e);
            }
        }
        fetchData();
    }, [props.tracks]);

    return (
        <StackedTracks transform={props.transform} id={props.id} height={0} onHeightChanged={props.onHeightChanged} svgRef={props.svgRef}>
            { React.Children.map( props.children, (child: React.ReactNode, i: number) => {             
                const dataRange  = data && data[i] && data[i].data && data[i].data[0] && ((data[i].data[0] as ValuedPoint).x !== undefined ? datarange(data[i].data as ValuedPoint[]): (data[i].data[0] as BigWigData).value !== undefined) && 
                    wigdatarange(data[i].data as BigWigData[]);
                const range = dataRange ? { min: formatPoint(dataRange.min), max: formatPoint(dataRange.max) } : undefined;
                const noData = data && data[i] && (data[i].data === undefined || data[i].data.length === 0);
                return (
                    isReactElement(child) ? (
                        data
                            ? React.cloneElement( child, { ...child.props, data: data[i] && data[i].data && (data[i].data[0] as BigWigData).value !== undefined ? condensedData(data[i].data as BigWigData[],  props.tracks[i].preRenderedWidth!, props.tracks[i].start, props.tracks[i].end): data[i].data, range, noData, loading: noData ? false: loading, error} ) 
                            : (React.cloneElement( child, { ...child.props, loading: true, error } ))
                    ) : child
                );
            })}
        </StackedTracks>
    );

}
export default RestTrackSet;
