import React from 'react';

import WrappedTrack from '../wrapped';
import { WrappedSquishBigBedProps } from './types';
import { SquishBigBedProps, SquishBigBed } from '../../bigbed';
import { WrappedTrackProps } from '../types';

const WrappedSquishBigBed: React.FC<WrappedSquishBigBedProps> = props => (
    <WrappedTrack {...(props as WrappedTrackProps)} trackType='bigbed' displayMode='squish'>
        <SquishBigBed {...(props as SquishBigBedProps)} />
    </WrappedTrack>
);
export default WrappedSquishBigBed;
