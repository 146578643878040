import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Modal } from 'semantic-ui-react';

import { MergerProps, MergerState } from './types';

function Merger<T>(props: MergerProps<T>): React.ReactElement<MergerProps<T>> | null {
    const [state, setState] = useState<MergerState<T>>({
        results: [],
        index: 0,
    });

    const nextFile = useCallback(
        (results: T[]) => {
            setState({
                results: state.results.slice().concat(results),
                index: state.index + 1,
            });
        },
        [state]
    );

    const mergeFiles = useCallback(() => {
        if (props.files === undefined) return;
        if (state.index === props.files.length) {
            props.onComplete(state.results);
            setState({ results: state.results, index: -1 });
        } else if (state.index > -1) props.nextFile(props.files[state.index], nextFile);
    }, [state]);

    useEffect(() => mergeFiles(), [mergeFiles]);

    const index = useMemo(() => (props.files ? props.files.length - state.index : 0), [props.files, state.index]);

    return props.files && props.files.length ? (
        <Modal open>
            <Modal.Header>Reading files....</Modal.Header>
            <Modal.Content>
                {index} file{index === 1 ? '' : 's'} left
            </Modal.Content>
        </Modal>
    ) : null;
}
export default Merger;
