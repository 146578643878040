import React from 'react';
import { Modal, Button, Grid, ModalProps } from 'semantic-ui-react';
import { MergePromptProps } from './types';

function mergePromptProps(props: MergePromptProps): ModalProps {
    const { onAcceptSeparate, onAcceptMerge, ...results } = props;
    return results;
}

const MergePrompt: React.FC<MergePromptProps> = (props) => (
    <Modal {...mergePromptProps(props)}>
        <Modal.Header>You have selected multiple files.</Modal.Header>
        <Modal.Content>
            <Grid style={{ textAlign: 'center', fontSize: '1em' }}>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <h2>How would you like to search?</h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={1} />
                    <Grid.Column width={6}>
                        <Button size="huge" onClick={props.onAcceptSeparate}>
                            Keep the files separate
                        </Button>
                        <p style={{ marginTop: '0.8em', fontSize: '1em' }}>
                            You will be able to view and download results from each file and on separate tabs.
                        </p>
                    </Grid.Column>
                    <Grid.Column width={2} />
                    <Grid.Column width={6}>
                        <Button size="huge" onClick={props.onAcceptMerge}>
                            Merge the files
                        </Button>
                        <p style={{ marginTop: '0.8em', fontSize: '1em' }}>
                            You will get a single set of all the results which match regions from any of the files.
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Modal.Content>
    </Modal>
);
export default MergePrompt;
