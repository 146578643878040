import React from 'react';

import WrappedTrack from '../wrapped';
import { WrappedSquishMotifProps } from './types';
import { SquishMotif, SquishMotifProps } from '../../motif';
import { WrappedTrackProps } from '../types';

const WrappedSquishMotif: React.FC<WrappedSquishMotifProps> = props => (
    <WrappedTrack {...(props as WrappedTrackProps)} trackType='motif' displayMode='squish'>
        <SquishMotif {...(props as SquishMotifProps)} />
    </WrappedTrack>
);
export default WrappedSquishMotif;
