import React, { useCallback, useContext } from "react";
import { svgPoint } from "../utils/svg";
import { TooltipContext } from "../components/tooltip/tooltipcontext";

type MouseOverEvent<T> = (event: React.MouseEvent<SVGRectElement>, v: T) => void;
type MouseOutEvent = () => void;
type TooltipCallbacks<T> = [ MouseOverEvent<T>, MouseOutEvent ];

export function useTooltip<T>(
    Tooltip: React.FC<T>, width: number, svgRef?: React.RefObject<SVGSVGElement>, onMouseOver?: MouseOverEvent<T>, onMouseOut?: MouseOutEvent
): TooltipCallbacks<T> {
    const tooltipContext = useContext(TooltipContext);
    const mouseOver = useCallback((event: React.MouseEvent<SVGRectElement>, v: T) => {  
        if (!svgRef || !svgRef.current) return;
        const [ x, y ] = svgPoint(svgRef.current, event);
        tooltipContext!.setTooltip({
            show: true,
            x: x > width - 100 ?  x - 90 :  x + 10, 
            y: y + 10,
            width: 100,
            height: 50,
            content: React.createElement(Tooltip, v)
        });
        onMouseOver && onMouseOver(event, v);
    }, [ Tooltip, width, svgRef, tooltipContext ]);
    const mouseOut = useCallback(() => {
        tooltipContext!.setTooltip({ show: false, x: 0, y: 0, content: undefined, width: 0, height: 0 });
        onMouseOut && onMouseOut();
    }, [ tooltipContext ]);
    return [ mouseOver, mouseOut ];
}
