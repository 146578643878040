import React from 'react';

import { ZoomButtonProps } from './types';

const ZoomButton: React.FC<ZoomButtonProps> = ({ text, factor, domain, onClick }) => (
    <input
        type="button"
        value={text}
        onClick={() => {
            const midpoint = (domain.end + domain.start) / 2.0;
            const width = (domain.end - domain.start) * factor;
            onClick({ start: Math.floor(midpoint - width / 2), end: Math.ceil(midpoint + width / 2) });
        }}
    />
);
export default ZoomButton;
