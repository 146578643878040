import React, { Children, useMemo, useState } from 'react';
import { Domain } from 'utils/types';

export type MagnificationProps = {
    width: number;
    id?: string;
    domain: Domain;
    transform?: string;
    svgRef?: React.RefObject<SVGSVGElement>;
    onHeightChanged?: (height: number) => void;
    zoomFactor?: number;
};

const Magnification: React.FC<MagnificationProps> = props => {
    const child = useMemo( () => Children.only(props.children), [ props ] ) as React.ReactElement;
    const [ height, setHeight ] = useState(0);
    const [ mouseOver, setMouseOver ] = useState<number | null>(null);
    const zoomFactor = props.zoomFactor || 10;
    const zoomedDomain = useMemo( () => {
        const center = (props.domain.end + props.domain.start) / 2;
        const width = (props.domain.end - props.domain.start) / zoomFactor;
        return { ...props.domain, start: Math.round(center - width / 2), end: Math.round(center + width) };
    }, [ props.domain ] );
    console.log(height);
    return (
        <g width={props.width} height={height} onMouseOver={e => setMouseOver(e.clientX)} onMouseOut={() => setMouseOver(null)}>
            { React.cloneElement(child, {
                ...child.props,
                onHeightChanged: (height: number) => { props.onHeightChanged && props.onHeightChanged(height); setHeight(height); }
            })}
            { mouseOver && (
                <>
                    { React.cloneElement(child, {
                        ...child.props,
                        transform: (child.props.transform || "") + ` translate(${mouseOver},0)`,
                        onHeightChanged: (height: number) => { props.onHeightChanged && props.onHeightChanged(height); setHeight(height); },
                        width: child.props.width / zoomFactor,
                        domain: zoomedDomain
                    }) }
                </>
            )}
        </g>
    )
};
export default Magnification;
