import { A,C,G,T,Logo, LogoWithNegatives } from 'logots-react';
import React from 'react';


const ALPHABET  = [
    { component: [A], regex: ["A"], color: ['red'] },
    { component: [C], regex: ["C"], color: ['blue'] },
    { component: [G], regex: ["G"], color: ['orange'] },
    { component: [T], regex: ["T"], color: ['#228b22'] },
  ];




/**
 * Properties of an ImportanceTrack component.
 * @member width the width of the component in SVG coordinates.
 * @member height the height of the component in SVG coordinates.
 * @member transform optional SVG transform to apply to the component.
 * @member allowSelection if set, allows the user to click and drag to select sequences from the track.
 * @member onSelectionEnd callback receiving information about a selection when the user releases the mouse.
 * @member onBaseMousedOver callback receiving information about a moused over base in the sequence.
 * @member onBaseMousedOut callback triggered when the user mouses out of a previously moused over base.
 * @member onBaseClicked callback receiving information about a clicked base in the sequence.
 * @member svgRef optional React reference to the containing SVG component.
 * @member zeroLineProps optional SVG properties of the horizontal line at importance=0.
 */
export type TrackProps = {
    width: number;
    height: number;
    transform?: string;
    allowSelection?: boolean;  
    svgRef?: React.RefObject<SVGSVGElement>;
    zeroLineProps?: React.SVGProps<SVGLineElement>;
};

/**
 * Properties of an ImportanceTrack component.
 * @member data the sequence/importance data to render.
 */
export type ContribTrackProps = TrackProps & {
    data: number[][];
};

/**
 * 
 * A sequence/importance track. Must be rendered within an SVG element. Data may be provided as a sequence string
 * plus an array of importance values or an array of sequence/importance value pairs.
 * 
 * @param props component properties; see TrackProps and ImportanceTrackProps.
 * @returns a rendered ImportanceTrack component instance.
 */
const ContribTrack: React.FC<ContribTrackProps> = props => {   
    const hasNegative = props.data.flat().some(v => v < 0);
    return hasNegative ? (  
        <LogoWithNegatives negativealpha={0} width={props.width} height={props.height} alphabet={ALPHABET} values={props.data} />
    ) : (
        <Logo width={props.width} height={props.height} alphabet={ALPHABET} ppm={props.data} />  
    );
};
export default ContribTrack;
