import React, { useCallback, useState } from 'react';
import { useRenderedImportanceTrackAnnotations, useRenderedImportanceTrackData } from './hooks';
import Letter from './Letter';

export type ImportanceTrackAnnotation = {
    coordinates: [ number, number ];
    color: string;
    onMouseOver?: () => void;
    children?: React.ReactElement[];
};

export type ImportanceTrackDataPoint = {
    base: string;
    importance: number;
};

export type ImportanceTrackSequence = {
    sequence: string;
    importance: number[];
};

export type ImportanceTrackData = ImportanceTrackDataPoint[] | ImportanceTrackSequence;

export function isImportanceTrackSequence(data: ImportanceTrackData): data is ImportanceTrackSequence {
    return (data as ImportanceTrackSequence).sequence !== undefined;
}

/**
 * Properties of an ImportanceTrack component.
 * @member width the width of the component in SVG coordinates.
 * @member height the height of the component in SVG coordinates.
 * @member transform optional SVG transform to apply to the component.
 * @member allowSelection if set, allows the user to click and drag to select sequences from the track.
 * @member onSelectionEnd callback receiving information about a selection when the user releases the mouse.
 * @member onBaseMousedOver callback receiving information about a moused over base in the sequence.
 * @member onBaseMousedOut callback triggered when the user mouses out of a previously moused over base.
 * @member onBaseClicked callback receiving information about a clicked base in the sequence.
 * @member svgRef optional React reference to the containing SVG component.
 * @member zeroLineProps optional SVG properties of the horizontal line at importance=0.
 */
export type TrackProps = {
    width: number;
    height: number;
    transform?: string;
    allowSelection?: boolean;
    onSelectionEnd?: (coordinates: [ number, number ], values: ImportanceTrackDataPoint[]) => void;
    onHeightChanged?: (value: number) => void;
    onBaseMousedOver?: (x: ImportanceTrackDataPoint, i: number) => void;
    onBaseClicked?: (x: ImportanceTrackDataPoint, i: number) => void;
    onBaseMousedOut?: () => void;
    svgRef?: React.RefObject<SVGSVGElement>;
    zeroLineProps?: React.SVGProps<SVGLineElement>;
    annotations?: ImportanceTrackAnnotation[];
};

/**
 * Properties of an ImportanceTrack component.
 * @member data the sequence/importance data to render.
 */
export type ImportanceTrackProps = TrackProps & {
    data: ImportanceTrackData;
    onSequenceLoaded?: (sequence: string) => void;
};

function arange(s: number, e: number) {
    const r: number[] = [];
    for (let i = s; i < e; ++i) r.push(i);
    return r;
}

/**
 * 
 * A sequence/importance track. Must be rendered within an SVG element. Data may be provided as a sequence string
 * plus an array of importance values or an array of sequence/importance value pairs.
 * 
 * @param props component properties; see TrackProps and ImportanceTrackProps.
 * @returns a rendered ImportanceTrack component instance.
 * 
 */
const ImportanceTrack: React.FC<ImportanceTrackProps> = props => {

    /* initialize data, compute element scale factors */
    const [ rendered, transform, rawTransform ] = useRenderedImportanceTrackData(props.data, props.height);
    const renderedAnnotations = useRenderedImportanceTrackAnnotations(props.annotations || [], rendered, rawTransform);
    const [ selection, setSelection ] = useState<[ number, number ] | null>(null);
    const letterScale = props.width / rendered.length / 100;
    const zero = transform(0)[0];

    /* initialize callbacks related to selection events */
    const updateSelection = useCallback( (x: ImportanceTrackDataPoint, i: number, create?: boolean) => {
        const handler = create ? props.onBaseClicked : props.onBaseMousedOver;
        if (handler) handler(x, i);
        if (!create && selection === null) return;
        if (!props.allowSelection) return;
        setSelection([ selection === null ? i : selection[0], i ]);
    }, [ selection, props.onBaseMousedOver, props.allowSelection ]);
    const onSelectionEnd = useCallback( () => {
        if (!selection) return;
        const coordinates = selection[0] < selection[1] ? selection : [ selection[1], selection[0] ] as [ number, number ];
        props.onSelectionEnd && props.onSelectionEnd(coordinates, arange(...coordinates).map(i => rendered[i]));
        setSelection(null);
    }, [ selection, props.onSelectionEnd ]);

    return (
        <g transform={props.transform}>
            { selection !== null && (
                <rect
                    fill="#93ceed"
                    fillOpacity={0.4}
                    x={Math.min(...selection) * letterScale * 100}
                    width={100 * letterScale * Math.abs(selection[1] - selection[0])}
                    height={props.height}
                />
            )}
            { renderedAnnotations.map( annotation => {
                const width = 100 * letterScale * Math.abs(annotation.coordinates[1] - annotation.coordinates[0]);
                const x = Math.min(...annotation.coordinates) * letterScale * 100;
                const y = annotation.y + annotation.height;
                const toffset = 80;
                return (
                    <>
                        <rect
                            fill={annotation.color}
                            fillOpacity={0.4}
                            x={x}
                            width={width}
                            height={annotation.height}
                            y={annotation.y}
                        />
                        <path
                            fill={annotation.color}
                            fillOpacity={0.4}
                            d={`M ${x} ${y} L ${x - width} ${y + toffset} L ${x - width} ${y + annotation.height * 3} L ${x + width * 2} ${y + annotation.height * 3} L ${x + width * 2} ${y + toffset} L ${x + width} ${y} L ${x} ${y}`}
                        />
                        <g transform={`translate(${x - width},${y + toffset})`}>
                            {annotation.children}
                        </g>
                    </>
                );
            })}
            { rendered.filter(x => x.importance).map( (x, i) => (
                <Letter
                    totalWidth={letterScale * 100}
                    totalHeight={props.height}
                    x={i * letterScale * 100}
                    xScale={letterScale}
                    transform={transform}
                    key={i}
                    onMouseOver={() => updateSelection(x, i)}
                    onMouseOut={props.onBaseMousedOut}
                    onMouseUp={onSelectionEnd}
                    onClick={() => updateSelection(x, i, true)}
                    {...x}
                />
            ) )}
            {rendered.filter(x => x.importance).length>0 ? <line
                stroke="#ff0000"
                strokeWidth={1}
                {...props.zeroLineProps}
                x1={0}
                x2={props.width}
                y1={zero}
                y2={zero}
            /> : <text x={0} y={props.height/2}>No importance track data</text>}
        </g>
    );

};
export default ImportanceTrack;
