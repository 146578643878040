import React from 'react';

import { xtransform } from '../../utils/coordinates';
import { CytobandsProps, CytobandColors } from './types';
import Cytoband from './cytoband';
import Centromere from './centromere';
import CytobandHighlight from './highlight';

const DEFAULT_CYTOBAND_COLORS: CytobandColors = {
    default: "#000000",
    centromere: "#880000",
    stalk: "#ff0000"
};

const Cytobands: React.FC<CytobandsProps> = props => {

    const colors = props.colors || DEFAULT_CYTOBAND_COLORS;
    const domain = props.domain || { start: 0, end: Math.max(...props.data.map( x => x.coordinates.end )) };
    const x = xtransform(domain, props.width);
    let centromereCount = 0;

    return (
        <g width={props.width} height={props.height} transform={props.transform}>
            { props.data.map( (cytoband, i) => {
                const xc = x(cytoband.coordinates.start);
                const width = x(cytoband.coordinates.end) - xc;
                return cytoband.stain === "acen"
                    ? <Centromere width={width} x={xc} height={props.height} color={colors.centromere} key={i} opening={centromereCount++ === 0} />
                    : <Cytoband type={cytoband.stain} width={width} x={xc} height={props.height} colors={colors} key={i} />
            }) }
            { props.highlight && <CytobandHighlight highlight={props.highlight} x={x} height={props.height} width={props.width} /> }
        </g>
    );
    

}
export default Cytobands;
