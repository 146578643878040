import { Population } from "./types";

export const VARIANT_QUERY = `
  query SNP($assembly: String!, $coordinates: [GenomicRangeInput]) {
    snpQuery(assembly: $assembly, coordinates: $coordinates, common: true) {
      id
      coordinates {
        chromosome
        start
        end
      }
    }
  }
`;

export const LD_QUERY = (populations: Population[]) => `
query LD($assembly: String!, $snpids: [String!]) {
  snpQuery(assembly: $assembly, snpids: $snpids) {
    ${populations.map( population => `
      ${population.population}_${population.subpopulation || ""}_ld: linkageDisequilibrium(
        population: ${population.population}
        ${population.subpopulation ? `subpopulation: ${population.subpopulation}` : ""}
      ) {
        id
        rSquared
        coordinates(assembly: $assembly) {
          start
          end
        }
      }
    `)}
  }
}
`;
