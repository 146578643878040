import React, { useContext } from "react";
import { TooltipProps } from './types';
import { TooltipContext } from './tooltipcontext';

const Tooltip: React.FC<TooltipProps> = (props) => {
  const { x, y, show } = useContext(TooltipContext);
  return (
    <g
      transform={`translate(${x}, ${y})`}
      style={{ visibility: show ? "visible" : "hidden" }}
    >
      <foreignObject width={props.width} height={props.height} style={{ overflow: 'visible' }}>
        {props.children}
      </foreignObject>
    </g>
  );
};

export default Tooltip;
