import React from 'react';
import { Modal, Button, Container, ModalProps } from 'semantic-ui-react';

import { CancelableModalProps } from './types';

function modalProps(props: CancelableModalProps): ModalProps {
    const { onCanceled, title, ...results } = props;
    return results;
}

const CancelableModal: React.FC<CancelableModalProps> = (props) => (
    <Modal {...modalProps(props)} onClose={props.onCanceled}>
        {props.title && <Modal.Header>{props.title}</Modal.Header>}
        <Modal.Content>
            <Container style={{ width: '80%', textAlign: 'center' }}>{props.children}</Container>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={props.onCanceled}>Cancel</Button>
        </Modal.Actions>
    </Modal>
);
export default CancelableModal;
