import { MultiSearchState, MultiSearchAction } from './types';

export function multiSearchReducer(state: MultiSearchState, action: MultiSearchAction): MultiSearchState {
    switch (action.type) {
        case 'FILES_RECEIVED':
            return action.files?.length === 1
                ? {
                      ...state,
                      searches: [...state.searches, Array.from(action.files)],
                      tab: state.searches.length,
                      selection: Array.from(action.files),
                      addingSearch: false,
                  }
                : {
                      ...state,
                      pendingUpload: action.files,
                      addingSearch: false,
                  };
        case 'FILE_MERGE_REQUESTED':
            return {
                ...state,
                searches: [...state.searches, Array.from(state.pendingUpload!)],
                selection: Array.from(state.pendingUpload!),
                tab: state.searches.length,
                pendingUpload: null,
            };
        case 'FILE_SEPARATE_REQUESTED':
            return {
                ...state,
                searches: [...state.searches, ...Array.from(state.pendingUpload!).map((x) => [x])],
                selection: [state.pendingUpload![0]],
                tab: state.searches.length,
                pendingUpload: null,
            };
        case 'TAB_SELECTED':
            return {
                ...state,
                tab: action.tab,
                selection: state.searches[action.tab],
            };
        case 'REGIONS_ADDED':
            return {
                ...state,
                searches: [...state.searches, action.regions],
                selection: action.regions,
                tab: state.searches.length,
                addingSearch: false,
            };
        case 'SEARCH_ADD_REQUESTED':
            return {
                ...state,
                addingSearch: true,
            };
        case 'SEARCH_ADD_CANCELED_ACTION':
            return {
                ...state,
                addingSearch: false,
            };
        case 'SET_SELECTION_ACTION':
            return {
                ...state,
                selection: action.selection,
            };
    }
}
