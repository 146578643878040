import React, { useMemo, useRef, useEffect } from 'react';
import { v1 as uuidv1 } from 'uuid';

import { DenseBamProps, BamRect } from './types';
import { xtransform } from '../../../utils/coordinates';
import { ClipPath } from '../../clippath';
import { renderDenseBamData } from './utils';

const DenseBam: React.FC<DenseBamProps> = props => {

    const uuid = useRef(uuidv1());
    const x = xtransform(props.domain, props.width);
    const color = props.color || "#000000";
    const id = props.id || uuid.current.toString();
    const rendered: BamRect[] = useMemo(
        () => renderDenseBamData(props.data!, x),
        [ props.domain, props.width, props.data ]
    ); 
    useEffect( () => {
        props.onHeightChanged && props.onHeightChanged(props.height);
    }, [ props.height ]);

    return (
        <g
            width={props.width}
            height={props.height}
            transform={props.transform}
            clipPath={`url(#${id})`}
        >
            <defs>
                <ClipPath id={id} width={props.width} height={props.height} />
            </defs>
            { rendered.map( (rect, i) => (
                <rect
                    key={`${id}_${i}`}
                    height={props.height * 0.6}
                    width={rect.end - rect.start}
                    x={rect.start}
                    y={props.height * 0.2}
                    fill={  rect.strand!==undefined ? (rect.strand ? '#F7F71A' : '#53F71A') : rect.color || color}
                />
            ))}
        </g>
    );
    
}
export default DenseBam;
