import React, { useMemo, useRef, useEffect, useContext } from 'react';
import { v1 as uuidv1 } from 'uuid';

import { SquishMotifProps, Rect } from './types';
import { xtransform } from '../../../utils/coordinates';
import { ClipPath } from '../../clippath';
import { renderSquishMotifData } from './utils';
import { TooltipContext } from "../../tooltip/tooltipcontext";
import { svgPoint } from './../../../utils/svg';
import { DNALogo } from 'logots-react';

const SquishMotif: React.FC<SquishMotifProps> = props => {
    const tooltipContext = useContext(TooltipContext);
    const mouseOver = (event:  React.MouseEvent<SVGRectElement>, rect: Rect) => {  
        if (props.svgRef) {
            const mousePosition = svgPoint(props.svgRef.current!,event) 
            tooltipContext.setTooltip({
                show: true,
                x: mousePosition[0] > props.width - (rect.pwm!.length*20) ?  (mousePosition[0] - (rect.pwm!.length*20)) + 10:  mousePosition[0] +10, 
                y: mousePosition[1],
                width: (rect.pwm!.length*20),
                height: 150,
                content: (<DNALogo
                    ppm={rect.pwm}   
                    mode={'INFORMATION_CONTENT'}
                    width={(rect.pwm!.length*20)} height={150}                             
                /> )
            });
        }        
    };
    const mouseOut = () => {
        tooltipContext.setTooltip({ show: false, x: 0, y: 0, content: undefined, width: 0, height: 0 });
    };
    const uuid = useRef(uuidv1());
    const x = xtransform(props.domain, props.width);
    const rendered: Rect[][] = useMemo(
        () => renderSquishMotifData(props.data!, x, props.domain),
        [ props.data, props.domain, props.width ]
    );
    const renderedPeaks: Rect[][] = useMemo(
        () => renderSquishMotifData(props.peaks || [], x, props.domain),
        [ props.peaks, props.domain, props.width ]
    );
    const height = props.rowHeight * rendered.length;
    const id = props.id || uuid.current.toString();

    useEffect( () => {
        props.onHeightChanged && props.onHeightChanged(height);
    }, [ rendered, props.rowHeight, props.onHeightChanged ]);
    
    return (
        <g
            width={props.width}
            height={height}
            transform={props.transform}
            clipPath={`url(#${id})`}
        >
            <defs>
		        <ClipPath
                    id={id}
                    width={props.width}
                    height={height}
                />
            </defs>
            { renderedPeaks.map( (group, i) => (
		        <g transform={`translate(0, ${i * props.rowHeight})`} key={`group_peak_${i}`}>
		            {group.map( (rect, j) => (
                        <rect
                            key={`${id}_peak_${i}_${j}`}
                            height={props.rowHeight * 0.6}
                            width={rect.end - rect.start < 1 ? 1 : rect.end - rect.start}
                            x={rect.start}
                            y={props.rowHeight * 0.2}
                            fill={props.peakColor || "#3287a8"}
                        />
		            ))}
		        </g>
	        ))}
            { rendered.map( (group, i) => (
		        <g transform={`translate(0, ${i * props.rowHeight})`} key={`group_${i}`}>
		            {group.map( (rect, j) => (
                        <rect
                            key={`${id}_${i}_${j}`}
                            height={props.rowHeight * 0.6}
                            width={rect.end - rect.start < 1 ? 1 : rect.end - rect.start}
                            x={rect.start}
                            y={props.rowHeight * 0.2}
                            fill={props.color || "#000088"}
                            onMouseOut={mouseOut} 
                            onMouseOver={(e: React.MouseEvent<SVGRectElement>)=> { 
                                e.persist(); 
                                if(rect.pwm) mouseOver(e,rect);                        
                            }} 
                        />
		            ))}
		        </g>
	        ))}
        </g>
	);
    
}
export default SquishMotif;
