import React, { useState } from 'react';
import { isReactElement } from '../../../utils';
import { StackedTracks } from '../../browser';
import { BIGDATA_QUERY } from './queries';
import { GraphQLTrackSetProps, BigResponse } from './types';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { datarange } from './../bigwig/utils';
import { ValuedPoint } from 'utils/types';
import { formatPoint } from '../../../utils/coordinates';

const GraphQLTrackSet: React.FC<GraphQLTrackSetProps> = props => {

    const [ data, setData ] = useState<BigResponse[] | null>(null);
    const [ loading, setLoading] = useState<boolean>(false);
    const [ error, setError] = useState<string | undefined>();
    useDeepCompareEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(props.endpoint, {
                    method: "POST",
                    body: JSON.stringify({
                        query: BIGDATA_QUERY,
                        variables: { bigRequests: props.tracks }
                    }),
                    headers: { "Content-Type": "application/json" }
                });
                const d = (await response.json())
                setData(d.data?.bigRequests || null);    
                if (d?.errors && d?.errors.length > 0) setError(d?.errors.toString());
                setLoading(false);
            } catch (e) {
                setLoading(false);
                setError(`Error loading data: ${e.message}`);
            }               
        };
        fetchData();
    }, [ props.tracks ]);
    return (
        <StackedTracks transform={props.transform} id={props.id} height={0} onHeightChanged={props.onHeightChanged} svgRef={props.svgRef}>
            { React.Children.map( props.children, (child: React.ReactNode, i: number) => {             
                const dataRange = data && data[i] && data[i].data && data[i].data[0] && (data[i].data[0] as ValuedPoint).x !== undefined && datarange(data[i].data as ValuedPoint[]);
                const range = dataRange ? { min: formatPoint(dataRange.min), max: formatPoint(dataRange.max) } : undefined;
                const noData = (data && data[i] && (data[i].data === undefined || data[i].data.length === 0)) || (dataRange && (dataRange.min  === Infinity && dataRange.max === -Infinity));
                return (
                    isReactElement(child) ? (
                        data
                            ? React.cloneElement( child, { ...child.props, data: data[i] && data[i].data, range, noData, loading, error } ) 
                            : (React.cloneElement( child, { ...child.props, loading: true, error } ))
                    ) : child
                );
            })}
        </StackedTracks>
    );

}
export default GraphQLTrackSet;
