import React, { useState, useCallback } from 'react';

import { InputRegionProps } from './types';

const InputRegion: React.FC<InputRegionProps> = props => {

    const [ text, setText ] = useState(props.text || (props.domain && (
        props.chromosome + ':' + props.domain.start + '-' + props.domain.end
    )));
    const onSubmit = useCallback( () => {
        const p = text.replace(/[:-]+/g, ' ').replace(/,/g, "").match(/\S+/g) || [];
        if (p.length === 3 && p[0].startsWith("chr"))
            props.onSubmit && props.onSubmit({ start: +p[1], end: +p[2] });
    }, [ text ]);

    return (
        <>
            <input size={props.size} type="text" value={text} onChange={e => setText(e.target.value)} />
            <input type="button" onClick={onSubmit} value="go" />
        </>
    );
    
};
export default InputRegion;
