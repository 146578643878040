import React from 'react';

import { ShiftButtonProps } from './types';

const ShiftButton: React.FC<ShiftButtonProps> = ({ text, shift, domain, onClick }) => (
    <input
        type="button"
        value={text}
        onClick={() => onClick({ start: Math.floor(domain.start + shift), end: Math.ceil(domain.end + shift) })}
    />
);
export default ShiftButton;
