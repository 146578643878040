import React, { useRef, useMemo } from 'react';
import { v1 as uuidv1 } from 'uuid';

import { xtransform } from '../../../utils/coordinates';
import { ClipPath } from '../../clippath';
import { LinkTrackProps } from './types';
import { renderRegions, renderLinks } from './utils';

/**
 * Represents a BigBed track rendered in dense mode as at UCSC. A dense BigBed renders genomic regions
 * of interest (such as peaks) as rectangles in a single row, with overlapping regions merged into a
 * single rectangle.
 */
const LinkTrack: React.FC<LinkTrackProps> = props => {

    const uuid = useRef(uuidv1());
    const x = xtransform(props.domain, props.width);
    const color = props.color || "#000000";
    const scoreThreshold = props.scoreThreshold || 0;

    const renderedRegions = useMemo(
        () => renderRegions( props.data, x, color ),
        [ props.data, props.domain, props.width, props.color ]
    );
    const renderedLinks = useMemo(
        () => renderLinks( props.data, x, props.height, scoreThreshold ),
        [ props.data, props.domain, props.height, props.width, props.scoreThreshold ]
    );

    return (
        <g width={props.width} height={props.height} transform={props.transform} clipPath={`url(#${uuid.current})`}>
            <defs>
                <ClipPath id={uuid.current} width={props.width} height={props.height} />
            </defs>
            { renderedRegions.map( (rect, i) => (
                <rect
                    key={`${props.id}_${i}`}
                    height={props.height / 4}
                    width={rect.end - rect.start < 2 ? 2 : rect.end - rect.start}
                    x={rect.start}
                    y={props.height * 3 / 4}
                    fill={rect.fill}
                />
            ))}
            { renderedLinks.map( (path, i) => (
                <path d={path.d} fill={path.fill} fillOpacity={props.arcOpacity || 0.7} key={`${props.id}_path_${i}`} />
            ))}
        </g>
    );
    
}
export default LinkTrack;
