import React, { useMemo, useRef, useEffect } from 'react';
import { v1 as uuidv1 } from 'uuid';

import { SquishBamProps, BamRect } from './types';
import { xtransform } from '../../../utils/coordinates';
import { ClipPath } from '../../clippath';
import { renderSquishBamData } from './utils';

/**
 * Represents a Bam track rendered in squish mode as at UCSC. A squished Bam renders genomic regions
 * of interest as narrow rectangles packed into an optimal number of rows so overlapping regions
 * are distinguishable from one another.
 */
const SquishBam: React.FC<SquishBamProps> = props => {
    
    const uuid = useRef(uuidv1());
    const x = xtransform(props.domain, props.width);
    const color = props.color || "#000000";
    const rendered: BamRect[][] = useMemo(
        () => renderSquishBamData(props.data!, x, props.domain),
        [ props.data, props.domain, props.width ]
    );
    const height = props.rowHeight * rendered.length;
    const id = props.id || uuid.current.toString();

    useEffect( () => {
        props.onHeightChanged && props.onHeightChanged(height);
    }, [ rendered, props.rowHeight, props.onHeightChanged ]);
    return (
        <g
            width={props.width}
            height={height}
            transform={props.transform}
            clipPath={`url(#${id})`}
        >
            <defs>
		        <ClipPath
                    id={id}
                    width={props.width}
                    height={height}
                />
            </defs>
            { rendered.map( (group, i) => (
		        <g transform={`translate(0, ${i * props.rowHeight})`} key={`group_${i}`}>
		            {group.map( (rect, j) => {       
                        let cgRects = rect.cigarOps.map((cg,k)=>{
                            let ops;
                            if(cg.op==='S')
                            {
                                ops = <rect
                                    key={`${j}_${k}`}
                                    height={props.rowHeight * 0.6}
                                    width={cg.opEnd - cg.opStart < 1 ? 1 : cg.opEnd  - cg.opStart}
                                    x={cg.opStart}
                                    y={props.rowHeight * 0.2}
                                    style={{ fill: 'none', strokeDasharray: '2,2', stroke: 'black', strokeWidth: 0.5 }}
                                />
    
                            }  else if(cg.op==='I') {
                                ops = <rect         
                                    key={`${j}_${k}`}
                                    height={props.rowHeight * 0.6}
                                    width={cg.opEnd - cg.opStart < 1 ? 1 : cg.opEnd  - cg.opStart}
                                    x={cg.opStart}
                                    y={props.rowHeight * 0.2}
                                    fill={'#ff69b4'}
                                >
                                    <title>Insertion</title>
                                </rect>                         
                            
                            } else if(cg.op==='D' || cg.op==='N') {
                                ops  = <line        
                                        key={`${j}_${k}`}         
                                        y2={props.rowHeight * 0.2 + (props.rowHeight * 0.6) / 2}
                                        x2={cg.opEnd}
                                        x1={cg.opStart}
                                        y1={props.rowHeight * 0.2 + (props.rowHeight * 0.6) / 2}
                                        stroke={'red'}
                                    />                                
                                
                            } else {
                                ops = <rect         
                                        key={`${j}_${k}`}                           
                                        height={props.rowHeight * 0.6}
                                        width={cg.opEnd - cg.opStart < 1 ? 1 : cg.opEnd  - cg.opStart}
                                        x={cg.opStart}
                                        y={props.rowHeight * 0.2}
                                        fill={  rect.strand!==undefined ? (rect.strand ? '#F7F71A' : '#53F71A') : rect.color || color}
                                    />                            
                            }
                            return ops;
                        })
                      
                        return (
                        <g key={props.id + '_' + j}>
                            {cgRects}
                            {props.domain.end - props.domain.start <= 200 && rect.seq && (
                                    <text
                                        fontSize={8}                                                
                                        x={rect.start}
                                        y={props.rowHeight * 0.2 + props.rowHeight * 0.5}
                                        fill={'black'}
                                        textLength={rect.end - rect.start}
                                        style={{ pointerEvents: 'none', WebkitTouchCallout: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none'}}            
                                    >
                                        {rect.seq}
                                    </text>
                                )} 
                        </g>)
                    })}
		        </g>
	        ))}
        </g>
	);
    
}
export default SquishBam;
