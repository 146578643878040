import React, { useMemo, useRef, useEffect, useContext } from 'react';
import { v1 as uuidv1 } from 'uuid';

import { DenseMotifProps, Rect } from './types';
import { xtransform } from '../../../utils/coordinates';
import { ClipPath } from '../../clippath';
import { renderDenseMotifData } from './utils';
import { TooltipContext } from "../../tooltip/tooltipcontext";
import { svgPoint } from './../../../utils/svg';
import { DNALogo } from 'logots-react';


const DenseMotif: React.FC<DenseMotifProps> = props => {

    const tooltipContext = useContext(TooltipContext);
    const mouseOver = (event: React.MouseEvent<SVGRectElement>, rect: Rect) => {  
        if(props.svgRef) {
            const mousePosition = svgPoint(props.svgRef.current!,event) 
            tooltipContext.setTooltip({
                show: true,
                x: mousePosition[0] > props.width - (rect.pwm!.length*20) ?  (mousePosition[0] - (rect.pwm!.length*20)) + 10:  mousePosition[0] +10, 
                y: mousePosition[1],
                width: (rect.pwm!.length*20),
                height: 150,
                content: (<DNALogo
                    ppm={rect.pwm}   
                    mode={'INFORMATION_CONTENT'}
                    width={(rect.pwm!.length*20)}
                    height={150}
                />)
            });
        }
    };
    const mouseOut = () => {
        tooltipContext.setTooltip({ show: false, x: 0, y: 0, content: undefined, width: 0, height: 0 });
    };
    const uuid = useRef(uuidv1());
    const x = xtransform(props.domain, props.width);
    const id = props.id || uuid.current.toString();

    const rendered: Rect[] = useMemo(
        () => renderDenseMotifData(props.data!, x, props.domain),
        [ props.domain, props.width, props.data ]
    ); 
    const renderedPeaks: Rect[] = useMemo(
        () => renderDenseMotifData(props.peaks || [], x, props.domain),
        [ props.domain, props.width, props.peaks ]
    );
    useEffect( () => {
        props.onHeightChanged && props.onHeightChanged(props.height);
    }, [ props.height ]);

    return (
        <g
            width={props.width}
            height={props.height}
            transform={props.transform}
            clipPath={`url(#${id})`}
        >
            <defs>
                <ClipPath id={id} width={props.width} height={props.height} />
            </defs>
            { renderedPeaks.map( (rect, i) => (
                <rect
                    key={`${id}_peak_${i}`}
                    height={props.height * 0.6}
                    width={rect.end - rect.start}
                    x={rect.start}
                    y={props.height * 0.2}
                    fill={props.peakColor || "#3287a8"}
                />
            ))}
            { rendered.map( (rect, i) => (
                <rect
                    key={`${id}_${i}`}
                    height={props.height * 0.6}
                    width={rect.end - rect.start}
                    x={rect.start}
                    y={props.height * 0.2}
                    fill={props.color || "#000088"}
                    onMouseOut={mouseOut} 
                    onMouseOver={(e: React.MouseEvent<SVGRectElement>)=> { 
                        e.persist(); 
                        if(rect.pwm) mouseOver(e,rect);                        
                    }} 
                />
            ))}
        </g>
    );
    
}
export default DenseMotif;
