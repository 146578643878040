import React from 'react';
import { Rect } from './types';

const STYLE = {
    fontSize: "12px",
    backgroundColor: "#ffffff",
    border: "1px solid",
    padding: "1em"
};

export const TooltipContent: React.FC<Rect> = rect => (
    <div style={STYLE}>
        {rect.name ? `name: ${rect.name}` : ""}<br />
        {rect.score !== undefined ? `score: ${rect.score}` : ""}
    </div>
);
export default TooltipContent;
