import React, { useContext } from "react";
import { ContextMenuProps, ContextMenuData } from './types';

const ContextMenuContext = React.createContext<ContextMenuData>({
    show: false,
    x: 0,
    y: 0,
    id: "",
    setContextMenu: () => {},
    displayMode: undefined
});

const ContextMenu: React.FC<ContextMenuProps> = (props) => {
  const { x, y, show } = useContext(ContextMenuContext);
  return (
    <g
      transform={`translate(${x}, ${y})`}
      style={{ visibility: show ? "visible" : "hidden" }}
    >
      <foreignObject width={props.width} height={props.height}>
        {props.children}
      </foreignObject>
    </g>
  );
};
export default ContextMenu;
export { ContextMenuContext };
