import { ContextMenuData } from './types';
import React, { useCallback, useState } from 'react';

type BrowserContextMenuProps = {
    data: ContextMenuData;
    onModeChange?: (id: string, mode: string) => void;
    setContextMenu: (contextmenu: ContextMenuData) => void;
};

const MENU_ITEM_STYLE = { cursor:'default', fontSize:'12px', padding: '6px 15px 5px 5px' };

const BrowserContextMenu: React.FC<BrowserContextMenuProps> = props => {
    
    const onItemClick = useCallback( (item: string) => {
        switch (item) {
            case 'download':
                props.data.downloadSVG && props.data.downloadSVG();
                break;
            default:
                props.onModeChange && props.onModeChange(props.data.id, item);
        }
        props.setContextMenu({ show: false, x: 0, y: 0, id: '' });
    }, [ props.data, props.onModeChange ]);
    const [ sItem, setItem ] = useState<string | null>(null);

    return (
        <div style={{ position: 'fixed', background: 'white', boxShadow: '0px 2px 10px #999999' }}>
            { props.data.displayModeOptions?.map( item =>
                <div
                    key={item}
                    onMouseOver={() => setItem(item)}
                    onMouseOut={() => setItem(null)}
                    onClick={() => onItemClick(item)}
                    style={{ backgroundColor: item === sItem ? '#e1e1e1' : 'white', ...MENU_ITEM_STYLE }}
                >
                    { props.data.displayMode === item ? <li>{item}</li> : item }
                </div>
            )}
        </div>
    );
    
};
export default BrowserContextMenu;
