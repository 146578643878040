import { ValuedPoint } from 'utils/types';
import { BigWigData } from 'bigwig-reader';


export function parseMultiplexedResponse(data: string): Array<any> {

    const compiledData: Array<Array<string>> = [];
    const lines = data.split("\n");
    for (let line of lines) {
        if (line.trim().length == 0) continue;
        const lineSplit = line.split(":");
        const index = parseInt(lineSplit[0]);
        const type = lineSplit[1];
        if (compiledData[index] === undefined) compiledData[index] = [];
        if (type === "DATA") {
            const lineData = lineSplit.slice(2).join(":");
            compiledData[index].push(lineData);
        }
    }

    return compiledData.map((d) => {
        // For tests, this assumption is good enough
        const isJson = d[0].startsWith("[{");
        if (!isJson) {
            return d.join("");
        }

        let objResponse: Array<any> = [];
        for (let el of d) {
            const parsedEl = JSON.parse(el) as Array<any>;
            objResponse = objResponse.concat(parsedEl);
        }
        return objResponse;
    });
}


function initialPreRenderedValues(xdomain: { start: number, end: number }): ValuedPoint[] {
    let retval: ValuedPoint[] = [];
    for (let i: number = xdomain.start; i <= xdomain.end; ++i) {
        retval.push({
            x: i,
            max: -Infinity,
            min: Infinity
        });
    }
    return retval;
}

export function condensedData(data: BigWigData[], preRenderedWidth: number, start: number, end: number): ValuedPoint[] {

    let domain: { start: number, end: number } = { start: start, end: end } 
    let x: (i: number) => number = i => (i - domain.start) * preRenderedWidth / (domain.end - domain.start);

    let cbounds: { start: number, end: number } = { start: Math.floor(x(domain.start)), end: Math.floor(x(domain.end)) };
    let retval = initialPreRenderedValues(cbounds);

    data.forEach((point: BigWigData): void => {
        let cxs: number = Math.floor(x(point.start < domain.start ? domain.start : point.start));
        let cxe: number = Math.floor(x(point.end > domain.end ? domain.end : point.end));
        if (point.value < retval[cxs].min) {
            retval[cxs].min = point.value;
        }
        if (point.value > retval[cxs].max) {
            retval[cxs].max = point.value;
        }
        for (let i: number = cxs + 1; i <= cxe; ++i) {
            retval[i].min = point.value;
            retval[i].max = point.value;
        }
    });
    return retval;

}
